<template lang="html">
<quick-edit @input="onOddsChange" v-model="oddsVal" type="Number" :showButtons="false" :class="['text-warning']" v-if="isEditField" v-b-tooltip :title="errorMessage" />
<span v-else class="odds-value">{{oddsVal}}</span>
</template>

<script lang="js">
export default {
  name: 'odds-value',
  props: {
    odds: Number,
    isEditField: Boolean,
    color: String
  },
  data (){
    return {
      oddsVal: typeof(this.odds) != Number ? Number(this.odds) : this.odds,
      hasError: false,
      errorMessage: ''
    }
  },
  mounted() {
    let userOddsFormat = this.GetUserOddsFormat();
    this.setOdds(userOddsFormat);

    this.$root.$on('oddsFormatChanged', format => {
      this.setOdds(format);
    });
  },
  methods: {
    setOdds(format){
      this.oddsVal = (format == '1')
        ? this.odds
        : this.GetAmericanOdds(this.odds);
    },
    onOddsChange(){
      this.$nextTick(() => {
      this.hasError == false;
      this.errorMessage = '';
      let userOddsFormat = this.GetUserOddsFormat();

      if(userOddsFormat == '1' && this.oddsVal <= 1){
        this.hasError == true;
        this.errorMessage = this.$t('decimalOddsLimit');
        this.oddsVal = '1.01';
      }
      else if(userOddsFormat == '2' && Math.abs(this.oddsVal) <= 100)
      {
        this.oddsVal = '-10000';
        this.hasError == true;
        this.errorMessage = this.$t('americanOddsLimit');
      } else {
        let decimalOdds = this.GetDecimalOdds(this.oddsVal);
        this.$emit('on-odds-change', decimalOdds);
      }
      });
    }
  },
  watch: {
    'odds': function(){
      let userOddsFormat = this.GetUserOddsFormat();
      this.setOdds(userOddsFormat);
    }
  }
}
</script>