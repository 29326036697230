<template>
    <div>
        <h2>Cookie Policy</h2>
        <p>Effective Date: February 25, 2023</p>
        <p>A cookie is a small file containing numbers/letters that is placed on your computer when you browse the Good Bettor website. These cookies provide us with statistics that help us improve our site and enhance your user experience.</p>
        <p>Our cookies do not collect additional information about you, and they do not compromise the security of your computer. No information can be traced back to any individual person.</p>
        <h3>How Cookies are Used</h3>
        <p>We use cookies to collect statistical information about how many people are accessing our website on a daily, weekly, and monthly basis. We are also interested in knowing which countries our traffic is coming from, as well as which pages on our website are being accessed.</p>
        <p>This information is collected to improve our website and provide a better experience for our users.</p>
        <h3>Disabling Cookies</h3>
        <p>You have the option to stop cookies at any time by adjusting the settings on your computer. Please note that if you choose to disable cookies, some features of the Good Bettor website may not be available to you.</p>
    </div>
</template>

<script>
export default {
  name: 'cookies',
}
</script>