import Cookies from 'js-cookie'

export default function(to, from, next) {

  if (checkIfUserIsLoggedIn()) {
    next();
  } else {
    next('/');
  }

  function checkIfUserIsLoggedIn () {
    return !!Cookies.get('token') && IsAdmin();
  }
  function IsAdmin() {
    const vuex = JSON.parse(localStorage.getItem('vuex'));
    return vuex.user ? vuex.user.isAdmin : false;
  }
}