import Vue from 'vue'
import Router from 'vue-router'
import layout from '../layout'
import checkAuth from '../middleware/check-auth'
import checkAdmin from '../middleware/check-admin'
import Meta from 'vue-meta'

Vue.use(Router)
Vue.use(Meta)

export default new Router({
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: '/',
  routes: [
    {
        path: '/',
        name: 'landing',
        component: () => import('@/pages/landing')
    },
    {
      path: '/bet',
      component: layout,
      beforeEnter: checkAuth,
      children: [
        { path: '', name: 'bet', component: () => import('@/pages/event-list') }
      ]
    },
    {
      path: '/betleague',
      component: layout,
      beforeEnter: checkAuth,
      children: [
        { path: '', name: 'betleague', component: () => import('@/pages/event-league') }
      ]
    },    
    {
      path: '/vote',
      component: layout,
      children: [
        { path: '', name: 'vote', component: () => import('@/pages/event-list') }
      ]
    }, 
    {
      path: '/createevent',
      component: layout,
      beforeEnter: checkAuth,
      children: [
        { path: '', name: 'createevent', component: () => import('@/pages/create-event') }
      ]
    },
    {
      path: '/editevent/:eventId',
      component: layout,
      beforeEnter: checkAuth,
      children: [
        { path: '', name: 'editevent', component: () => import('@/pages/create-event') }
      ]
    },
    {
      path: '/eventdetail/:eventId',
      component: layout,
      beforeEnter: checkAuth,
      children: [
        { path: '', name: 'eventdetail', component: () => import('@/pages/event-detail') }
      ]
    },
    {
      path: '/gold/success',
      component: layout,
      beforeEnter: checkAuth,
      children: [
        { path: '', name: 'goldsuccess', component: () => import('@/pages/gold/success') }
      ]
    },
    {
      path: '/gold/cancel',
      component: layout,
      beforeEnter: checkAuth,
      children: [
        { path: '', name: 'goldcancel', component: () => import('@/pages/gold/cancel') }
      ]
    },
    /*{
      path: '/votedetail/:eventId',
      component: layout,
      children: [
        { path: '', name: 'votedetail', component: () => import('@/pages/event-detail') }
      ]
    },*/  
    {
      path: '/mybet',
      component: layout,
      beforeEnter: checkAuth,
      children: [
        { path: '', name: 'mybet', component: () => import('@/pages/mybet') }
      ]
    },
    {
      path: '/dashboard',
      component: layout,
      beforeEnter: checkAuth,
      children: [
        { path: '', name: 'dashboard', component: () => import('@/pages/dashboard') }
      ]
    },
    {
      path: '/mysubscription',
      component: layout,
      beforeEnter: checkAuth,
      children: [
        { path: '', name: 'mysubscription', component: () => import('@/pages/mysubscription') }
      ]
    },
    {
      path: '/leaderboard',
      component: layout,
      beforeEnter: checkAuth,
      children: [
        { path: '', name: 'leaderboard', component: () => import('@/pages/leaderboard') }
      ]
    },
    {
      path: '/myevent',
      component: layout,
      beforeEnter: checkAuth,
      children: [
        { path: '', name: 'myevent', component: () => import('@/pages/myevent') }
      ]
    },
    {
      path: '/survival',
      component: layout,
      beforeEnter: checkAuth,
      children: [
        { path: '', name: 'survival', component: () => import('@/pages/survival') }
      ]
    },
    {
      path: '/6enter',
      component: layout,
      beforeEnter: checkAdmin,
      children: [
        { path: '', name: 'hidden-admin', component: () => import('@/pages/6enter') }
      ]
    },
    {
      path: '/reset',
      name: 'reset',
      component: () => import('@/pages/reset')
    },
  /*{
      path: '/myvote',
      component: layout,
      children: [
        { path: '', name: 'myvote', component: () => import('@/pages/myvote') }
      ]
    },*/
    /*
    {
      path: '/myreport',
      component: layout,
      children: [
        { path: '', name: 'myreport', component: () => import('@/pages/myreport') }
      ]
    },*/
    {
      path: '/error-pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        { path: 'error-404', name: 'error-404', component: () => import('@/pages/error-pages/error-404') },
        { path: 'error-500', name: 'error-500', component: () => import('@/pages/error-pages/error-500') }
      ]
    },
    {
      path: '*',
      redirect: '/error-pages/error-404',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        { path: 'error-404', component: () => import('@/pages/error-pages/error-404') }
      ]
    }    
  ]
})
