import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import Cookies from 'js-cookie'

export default {
  install (Vue) {

    //use a new Vue instance as the interface for Vue components to receive/send SignalR events
    // this way every component can listen to events or send new events using this.$bobwinHub
    const bobwinHub = new Vue()
    Vue.prototype.$bobwinHub = bobwinHub

    // Provide methods to connect/disconnect from the SignalR hub
    let connection = null
    let startedPromise = null
    let manuallyClosed = false
    let retry = 0;

    const init = () => {
      const token = Cookies.get('token')
      connection = new HubConnectionBuilder()
        .withUrl(
          `${Vue.prototype.$http.defaults.baseURL}/bobWinHub`,
          { accessTokenFactory: () => token }
          //null
        )
        //.configureLogging(LogLevel.Information)
        .configureLogging(LogLevel.Debug)
        .build();

      // Forward hub events through the event, so we can listen for them in the Vue components
      connection.on('Received', (response) => {
        bobwinHub.$emit(response.callback, response);
      });

      connection.on('OptionUpdated', (response) => {
        bobwinHub.$emit('OptionUpdated', JSON.parse(response.data));
      })

      connection.on('EventUpdated', (response) => {
        bobwinHub.$emit('EventUpdated', JSON.parse(response.data));
      })
      
      connection.on('AppealRequested', (response) => {
        bobwinHub.$emit('AppealRequested', response);
      })
      
      // You need to call connection.start() to establish the connection but the client wont handle reconnecting for you!
      // Docs recommend listening onclose and handling it there.
      // This is the simplest of the strategies
      function start () {
        startedPromise = connection
          .start( {withCredentials: true} )
          .then( () => retry = 0 )
          .catch(err => {
            window.console.error('Failed to connect with hub', err)
            return new Promise((resolve, reject) => { 
              retry++;
              if (retry < 3) {
                setTimeout(() => start().then(resolve).catch(reject), 5000)
              } else {
                retry = 0;
                stop();
              }
            })
          })
        return startedPromise;
      }
      connection.onclose(() => {
        if (!manuallyClosed) start()
      })

      // Start everything
      manuallyClosed = false;
      start();
    }

    const close = () => {
      if (!startedPromise) {
        connection = null;
        return Promise.resolve('null')
      }
      manuallyClosed = true
      startedPromise
        .then(() => connection.stop())
        .then(() => { startedPromise = null; connection = null;
          return Promise.resolve('null')
        })
        return Promise.resolve('null')
    }

    const stop = (redirect = true) => {
      Cookies.remove('token');
      close().then((result)  =>  {
        window.console.log(result);
        if (redirect) {
          window.location.href = "/";
        }
      })
    }
    Vue.prototype.startSignalR = init;
    Vue.prototype.stopSignalR = stop;

    // Provide methods for components to send messages back to server
    // Make sure no invocation happens until the connection is established    
    bobwinHub.callHub = (data) => {
      if (connection === null) {
        init();
      }
      if (!startedPromise) return
      return startedPromise
        .then(() => connection.invoke('CallHub', data))
        .catch(window.console.error)
    }
    bobwinHub.authHub = (data) => {
      if (!startedPromise) return
      return startedPromise
        .then(() => connection.invoke('AuthHub', data))
        .catch(window.console.error)
    }
    bobwinHub.closeHub = () => {
      stop(false);
    }
  }
}