<template>
  <section class="withdraw">
    <b-modal id="token-purchase-modal" class="token-purchase-modal" size="md"  
        :title="$t('CIBPurchase')" :okTitle="$t('ok')" :cancelTitle="$t('cancel')"
        @ok="handleOk" @show="getUSDTPerCIB" @hidden="resetModal"
        :ok-disabled="hasError">
        
        <div class="p-3">
        <h6 class="text-info"> {{$t('tokenPurchaseMessage')}}</h6>
        <h5 class="text-warning">1 CIB = {{usdtPerCIB}} USDT</h5>
        
        <div class="row">
            <div class="col-12 mb-3">
                <b-input-group prepend="CIB">
                <b-form-input v-model="cibAmount" type="number" :class="['form-control','form-control-lg', hasError ? 'error' : '']" placeholder="Input amount" required min="0" step="1" @input="onAmountChange"></b-form-input>
                </b-input-group>        
            </div>

            <div class="col-12">
                <b-input-group :prepend="CurrentCurrency">
                <b-form-input v-model="usdtAmount" type="number" :disabled="true" :class="['inline-block','form-control','form-control-lg', hasError ? 'error' : '']"></b-form-input>
                </b-input-group>
            </div>
            
            <h6 class="text-danger p-2" v-if="hasError">! {{ errorMessage }}</h6>
        </div>

      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
data(){
    return {
        amount: 0,
        hasError: false,
        errorMessage: '',
        tokenType: '',
        usdtPerCIB: 0,
        defaultCIBAmount: 10,
        cibAmount: 0
    }
},
mounted () {
    this.$root.$on('openPurchaseDialog', () => {
        this.amount = 0;
        this.$bvModal.show('token-purchase-modal');
    });

    this.$bobwinHub.$on('usdtReceived', response => {
        if(response.result === 'success') {
            this.usdtPerCIB = response.data;
            this.setDefaultCIBAmount();
            this.checkStatus();
        }
    });

    this.$bobwinHub.$on('requestTransactionSaved', response => {
      this.$bvModal.hide('token-purchase-modal');
      if(response.result === 'success') {
        this.$swal({
            icon: 'success',
            title: this.$t('successTitle'),
            animation: false,
            confirmButtonText: this.$t('ok')
        });
        let user = JSON.parse(response.userData);
        this.$store.dispatch('setUser', user);
      }
      else {
        this.hasError = true;
        this.errorMessage = response.message;
      }
    });
},
computed: {
    usdtAmount: function(){
      return this.cibAmount * this.usdtPerCIB;
    }
  },
methods: {
    resetModal(){
        this.$bobwinHub.$off('addToBetslip');
        this.hasError = false;
        this.errorMessage = '';
        this.setDefaultCIBAmount();
    },
    setDefaultCIBAmount(){
        let availableCIB = this.GetFloorValue(this.User.balance / this.usdtPerCIB, 0);
        this.cibAmount = availableCIB > this.defaultCIBAmount ? this.defaultCIBAmount : availableCIB;
    },
    getUSDTPerCIB(){
        this.$nextTick(() => {
            var param = {
                TokenType: 'CIB',
                FromTokenType: 'USDT'
            }
            this.CallHub({task: 'GetTokenTransactionRate', callback: 'usdtReceived', data: JSON.stringify(param)});
        });
    },
    checkStatus(){
        if(this.usdtAmount > this.User.balance){
          this.hasError = true;
          this.errorMessage = this.$t('notEnoughBalance');
      }
    },
    onAmountChange() {
      this.hasError = false;
      this.errorMessage = '';
      this.checkStatus();
    },
    handleOk(bvModalEvt){
      bvModalEvt.preventDefault();
      if(this.usdtAmount > this.User.balance){
          this.hasError = true;
          this.errorMessage = this.$t('notEnoughBalance');
      }
      if(this.usdtAmount > 0){
        var param = {
          UserId: this.UserId,
          TokenType: 'CIB',
          FromTokenType: 'USDT',
          Rate: this.usdtPerCIB,
          Amount: this.cibAmount
        }
        this.CallHub({task: 'RequestTokenTransaction', callback: 'requestTransactionSaved', data: JSON.stringify(param)});
      }
    }

  }
}
</script>
<style scoped>
.input-group-text {
    width: 60px;
}
 .form-group {
      margin-bottom: 0.2rem;
    }
</style>