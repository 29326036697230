<template>
  <section class="signin">
    <b-modal id="signin-modal" class="signin-modal" :title="$t('signin')" size="md" button-size="lg" hide-footer @hidden="init">
        <div class="auth-form-transparent text-left p-3">
            <b-form @submit="signin">
                <b-form-group :label="$t('email')" label-for="email">
                    <b-form-input v-model="email" id="email" type="email" class="form-control" :placeholder="$t('email')" :state="state" :disabled="processing"  required />
                </b-form-group>

                <b-form-group :label="$t('password')" label-for="password">
                    <div class="d-flex">
                      <b-form-input v-model="password" id="password" :type="showPassword ? 'text' : 'password'" class="form-control" :placeholder="$t('password')" :state="state"  :disabled="processing" required />
                      <b-button type="button" @click="showPassword = !showPassword"><i class="fa fa-eye-slash" aria-hidden="true"></i></b-button>
                    </div>
                </b-form-group>
                <div class="text-danger mb-3" v-if="state === false">! {{ errorMessage }}</div>
                <div class="mt-3">
                    <b-button type="submit" variant="primary" class="btn-block btn-lg font-weight-medium" :disabled="processing" >{{ $t('signin').toUpperCase() }}</b-button>
                </div>
                <div class="text-center mt-4 font-weight-light">{{ $t('registerQuestion') }} <a href="#" class="text-warning"  role="link" :aria-disabled="processing" @click="goRegister">{{ $t('register') }}</a></div>
                <div class="text-center mt-4 font-weight-light"><a href="#" class="text-warning"  role="link" :aria-disabled="processing" @click="goForgotPassword">{{ $t('forgotPassword') }}?</a></div>
            </b-form>
        </div>
    </b-modal>
    <forgot-password />
  </section>

</template>

<script>
import Cookies from 'js-cookie'
import axios from 'axios'
import forgotPassword from './forgot-password.vue'

export default {
  name: 'signin',
  data(){
    return {
        email: '',
        password: '',
        showPassword: false,
        state: null,
        errorMessage: '',
        processing: false
    }
  },
  components: {
    forgotPassword,
  },
  created() {
  },
  methods: {
    init(){
      this.email = '';
      this.password = '';
      this.state = null;
      this.errorMessage = '';
      this.processing = false;
    },
    toggleShowHide() {
      this.showPassword != this.showPassword;
      return false;
    },
    signin(event){
      event.preventDefault();
      this.processing = true;
      this.signin2();
    },
    signin2() {
      axios.post('/api/Users/login', {
        Email: this.email,
        Password: this.password
      })
      .then(response => {
        Cookies.set('token', response.data.token, { expires: 1 });
        let user = JSON.parse(response.data.userData);
        this.$store.dispatch('setUser', user);
        this.$bvModal.hide('signin-modal');
        this.processing = false;
        this.$router.push({ path: '/betleague'});
      })
      .catch(error => {
        this.state = false;
        this.errorMessage = this.$t('loginError');
        this.processing = false;
        window.console.log(error);
      })
    },
    goRegister(){
      this.$bvModal.hide('signin-modal');
      this.$bvModal.show('register-modal');
    },
    goForgotPassword() {
      this.$bvModal.hide('signin-modal');
      this.$bvModal.show('forgot-password');
    }
  }
}
</script>