<template>
  <section class="main-view">
    <div class="container-scroller">
      <Sidebar/>
      <div class="container-fluid page-body-wrapper">
        <Header/>
        <div class="main-panel">
          
            <Banner />
          <div class="content-wrapper">
            <div>
              <router-view />
            </div>
            <vue-snotify></vue-snotify>
            
          </div>
          <Footer/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from "./partials/Header";
import Sidebar from "./partials/Sidebar";
import Footer from "./partials/Footer";
import Banner from "./partials/banner";
import {SnotifyPosition} from 'vue-snotify';

export default {
  name: "layout",
  components: {
    Header,
    Sidebar,
    Footer,
    Banner
  },
  beforeDestroy(){
    this.$root.$off('updateOptions');
  },
  mounted(){
    if (this.IsLoggedIn) {
          this.getWeb3Info();
          this.getCategories();
    }

    window.ethereum.on('connected', () => {
      this.ShowNotification('You have been connected to ' + window.ethereum.networkName +'(' + window.ethereum.networkId + ')', 'Info');
      this.GetUser();
    });

    window.ethereum.on('disconnected', () => {
      this.Logout();
    });

    window.ethereum.on('chainChanged', (chainId) => {
      var hexChainId = '0x'+Number(this.Web3Info.networkChainId).toString(16);
      this.ShowNotification('Network has been changed to ' + chainId, 'Info');
      if(chainId == hexChainId){
        this.GetUser();
      } else {
        this.Logout();
      }
    });

    window.ethereum.on('accountsChanged', (accounts) => {
      if(accounts.length > 0 && this.IsLoggedIn){
        this.ShowNotification('Account has been changed', 'Info');
        this.GetUser();
      } else {
        this.Logout();
      }
    });

    this.$bobwinHub.$on('userReceived', response => {
      if(response.result === 'success'){
        let user = JSON.parse(response.userData);
        this.$store.dispatch('setUser', user);
        if(user.reloadPage){
          this.$root.$emit('reload');
        }
      }
    });

    this.$bobwinHub.$on('categoriesReceived', response => {
      let categoryData = JSON.parse(response.data);
      this.$store.dispatch('setCategoryData', categoryData);
    });

    this.$bobwinHub.$on('web3InfoReceived', (response) => {
      let web3Info = JSON.parse(response.data);
      this.$store.dispatch('setWeb3Info', web3Info);
      this.$store.dispatch('setBobClock', this.$moment.utc(response.currentTime));
      this.StartBobClock();
    });

    this.$bobwinHub.$on('EventUpdated', evt => {
      this.$root.$emit('updateEventEnablement', evt);
    });

    this.$bobwinHub.$on('OptionUpdated', options => {
      options.forEach((option) => {
        if(option) {
          if(option.updateType === 2){
            this.$root.$emit('updateBetStatus', option);
          } else {
            this.$root.$emit('updateOptionStatus', option);
          }
        }
      });
    });

    this.$bobwinHub.$on('AppealRequested', (response) => {
      if(response.result === 'success' && this.IsAdmin){
        this.showAppealNotification();
      }
    });
  },
  methods: {
    getWeb3Info(){
      this.CallHub({task: 'GetWeb3Info', callback: 'web3InfoReceived'});
    },
    getCategories(){
      this.CallHub({task: 'GetEventCategories', callback: 'categoriesReceived'});
    },
    showAppealNotification(){
      this.$snotify.confirm(this.$t('appealNotificationMessage'), '', {
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          position: SnotifyPosition.rightTop,
          buttons: [
            {text: this.$t('goToAppealList'), action: (toast) => { this.goToAppealList(); this.$snotify.remove(toast.id); } },
            {text: this.$t('close'), action: (toast) => {this.$snotify.remove(toast.id); } },
          ]
        });
    },
    goToAppealList(){
      if(this.$route.name == 'admin/claim'){
        this.$root.$emit('reload');
      }
      else {
        this.$router.push({path: '/admin/claim'});
      }
    }
  }
}
</script>