import Vue from 'vue';
import Snotify, {SnotifyPosition} from 'vue-snotify';
import * as oddslib from 'oddslib';
import Cookies from 'js-cookie'

Vue.use(Snotify);

export default {
  data: function () {
    return {
      CurrentCurrency: 'Play Money',
      UserGold: 'GOLD',
      UserGem: 'GEM',
      BetButtonClassPrefix: ' btn-option-',
      OptionOddsClassPrefix: ' odds-',
      OptionOddsArrowClassPrefix: ' odds-arrow-',
      OptionOddsChangeButtonClassPrefix: ' odds-accept-change-',
      OptionStakeClassPrefix: ' stake-',
      UserConnectTimer: {},
      Tokens: [
        { text: 'USDT', value: '2', minValue: 10 },
        { text: 'CIB', value: '1', minValue: 1 },
        { text: 'gBOB', value: '3', minValue: 1 }
      ],
      OddsFormats: [
        { text: 'Decimal', value: 1},
        { text: 'American', value: 2}
      ]
    }
  },
  computed: {
    User: function(){
      return this.$store.state.user;
    },
    UserId: function() {
      return this.$store.state.user ? this.$store.state.user.id : '';
    },
    UserEmail: function() {
      return this.$store.state.user ? this.$store.state.user.email : '';
    },
    IsAdmin: function(){
      return this.$store.state.user ? this.$store.state.user.isAdmin : false;
    },
    IsLoggedIn: function() {
      return this.$store.state.user !== null && !!Cookies.get('token');
    },
    CategoryData: function() {
      return this.$store.state.categoryData;
    },
    Web3Info: function(){
      return this.$store.state.web3Info;
    }
  },
  methods: {
      CallHub(param){
        this.$bobwinHub.callHub(JSON.stringify(param));
      },
      AuthHub(param){
        this.$bobwinHub.authHub(JSON.stringify(param));
      },
      CloseHub(){
        this.$bobwinHub.closeHub();
      },
      StartBobClock() {
        setInterval( () => {
          this.$store.dispatch('setBobClock', this.$moment(this.$store.state.bobUTCTime).add(1, 'seconds'));
        }, 1000 );
      },
      GetWalletId() {
        return window.ethereum.selectedAddress;
      },
      async ConnectWallet(){
        var hexChainId = '0x'+Number(this.Web3Info.networkChainId).toString(16);
        if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {

          if(window.ethereum.isConnected()
            && window.ethereum.chainId == hexChainId
            && window.ethereum.selectedAddress != null){

            this.GetUser();

          } else {
            try {
              await window.ethereum.request({ method: 'eth_requestAccounts'})
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: hexChainId,
                  chainName: this.Web3Info.networkChainName,   
                  nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18
                  },
                  rpcUrls: [this.Web3Info.rpcUrl]
                }]
              });

            } catch(e) {
              window.console.error(e);
            }  
          }
        } else {
          this.$swal({
            title: this.$t('installMetamask'),
            icon: 'info',
            animation: false,
            showCancelButton: true,
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel')
          }).then((result) => {
            if (result.value) {
              window.open('https://metamask.io/download.html', '_blank');
            }
          }); 
        }
      },
      Logout(){
        this.$store.dispatch('setUser', null);
        Cookies.remove('token');
        window.location.href = "/";
      },
      GetUser(interval, reloead, callbackData){
        interval = interval==undefined ? 500 : interval;
        this.UserConnectTimer = setTimeout(() => {
          if(this.GetWalletId()){
            var param = {
              UserWallet: this.GetWalletId(),
              ReloadPage: reloead
            }
            this.CallHub({task: 'GetUser', callback: 'userReceived', data: JSON.stringify(param)});

            if (callbackData){
              this.$root.$emit('updateDepositStatus', callbackData);
            }
            
            clearTimeout(this.UserConnectTimer);
          }
        }, interval);
      },
      GetBetslipList(){
        return localStorage.getItem('betslip') ? JSON.parse(localStorage.getItem('betslip')) : [];
      },
      GetUserOddsFormat(){
        return localStorage.getItem('oddsFormat') ? localStorage.getItem('oddsFormat') : 1;
      },
      
      GetBetslipIndex(optionId){
        let index = -1;
        this.GetBetslipList().find(function(item, i){
          if(item.gameOptionId === optionId){
            index = i;
            return i;
          }
        });
        return index;
      },
      UpdateBetButton(optionId){
        let elements = [...document.getElementsByClassName(this.BetButtonClassPrefix + optionId)];
        elements.forEach((element) =>
        {
          let classList = element.classList;
          if(classList){
            classList.remove('btn-warning', 'btn-dark');
            if(this.GetBetslipIndex(optionId) > -1) {
              classList.add('btn-warning');
            } else {
              classList.add('btn-dark');
            }
          }
        });
      },
      ShowNotification (message, notificationType) {
          var options = {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            position: SnotifyPosition.rightTop
          };
          if(notificationType == 'Error'){
            this.$snotify.error(message, options);
          }
          else if (notificationType == 'Info'){
            this.$snotify.info(message, options);
          }
          else if (notificationType == 'Warning'){
            this.$snotify.warning(message, options);
          }
      },
      HandleError(error) {
        if(error.response && error.response.status == 400){
          let message = error.response.data.Message;
          this.ShowNotification(message, 'Error');
        }
      },
      ShowConfirmMessage(title, message, confirmFunc, denyFunc){
        this.$swal({
          icon: 'question',
          title: title,
          text: (typeof message === 'object' && message.message ? message.message : message),
          type: 'confirm',
          animation: false,
          showConfirmButton: true,
          showDenyButton: true,
          confirmButtonText: this.$t('yes'),
          denyButtonText: this.$t('no'),
        }).then((result) => {
          if (result.isConfirmed) {
            if (confirmFunc) confirmFunc();
          } else if (result.isDenied) {
            if (denyFunc) denyFunc();
          }
        });
      },
      ShowErrorMessage(message){
        this.$swal({
          icon: 'error',
          title: this.$t('failed'),
          text: (typeof message === 'object' && message.message ? message.message : message),
          type: 'error',
          animation: false,
          confirmButtonText: this.$t('ok')
        });
      },
      ShowSuccessMessage(message){
        this.$swal({
          icon: 'info',
          title: this.$t('successTitle'),
          text: (typeof message === 'object' && message.message ? message.message : message),
          type: 'info',
          animation: false,
          confirmButtonText: this.$t('ok')
        });
      },
      GetLogoUrl(fileName){
        try {
          try{
            return require("@/assets/images/logo/"+ fileName +".svg");
          }
          catch {
            return require("@/assets/images/logo/"+ fileName +".png");
          }
        } catch {
          return require("@/assets/images/logo/team_default.png");
        }
      },
      GetCategoryIcon(category){
        let icon = 'star';
        if(category != null){
          let categoryName = category.toLowerCase();
          switch(categoryName) {
            case 'baseball':
              icon = 'baseball-bat';
              break;
            case 'american football':
              icon = 'football';
              break;
            case 'aussie rules':
              icon = 'football-australian';
              break;
            case 'ice hockey':
              icon = 'hockey-sticks';
              break;
            case 'boxing':
              icon = 'boxing-glove';
              break;
            case 'esports':
              icon = 'controller-classic';
              break;
            case 'specials':
              icon = 'folder-star-outline';
              break;
            case 'mma':
              icon = 'mixed-martial-arts';
              break;
            case 'table tennis':
                icon = 'table-tennis';
                break;              
            default:
              icon = categoryName;
              break;
          }
        }
        return "mdi mdi-"+ icon;
      },
      GetLeagueIcon(name){
        try {
          return require("@/assets/images/logo/"+ 'league_' + name.replace(' ','').toUpperCase() +".svg");
        } catch {
          return require("@/assets/images/logo/team_default.png");
        }
      },
      GetDate(date, format, convertToLocal){
        convertToLocal = typeof convertToLocal !== 'undefined' ? convertToLocal : true;
        let dateFormat = format
          ? format
          : this.$i18n.locale == 'en' ? 'HH:mm MM.DD.YY' : 'YYYY-MM-DD HH:mm';
        let momentDate = convertToLocal ? this.$moment.utc(date).local() : this.$moment(date);
        return date ? momentDate.locale(this.$i18n.locale).format(dateFormat) : '';
      },
      GetFormattedDate(date){
        let calendarString = this.GetDateCalendarString(date);
        let format = this.$i18n.locale != 'en' ? 'LL' : 'Do MMMM';
        let localDateTime = this.GetDate(date, 'HH:mm').toString();
        return calendarString != '' ? calendarString.replace(localDateTime, '') : this.GetDate(date, format);
      },
      GetDateCalendarString(date){
        let localDate = this.$moment.utc(date).local();
        let dateDiff = this.$moment(localDate.format('YYYY-MM-DD')).diff(this.$moment().format('YYYY-MM-DD'), 'days');
        let showString = dateDiff <= 2 && dateDiff >=0
          ? true
          : false;
        return showString ? this.$moment(localDate).locale(this.$i18n.locale).calendar().split(' ')[0] : '';
      },
      GetFormattedDatetime(date){
        let dateString = this.GetFormattedDate(date);
        let calendarString = this.GetDateCalendarString(date);
        return dateString + (calendarString != '' && this.$i18n.locale == 'en' ? ' at ' : ' ') + this.GetDate(date, 'HH:mm');
      },
      GetFormattedMonth(monthNumber){
        const date = new Date(Date.UTC(2000, monthNumber - 1, 1));
      return date.toLocaleString(this.$i18n.locale, { month: 'long' });
      },
      SetSidebarColor(){
        let currentRole = this.GetCurrentRole();

        let roles = ['bettor', 'bookmaker', 'oracle','admin'];
        let sidebar = document.getElementById('sidebar');
        let nav = document.getElementById('nav');
        
        if(sidebar){
          roles.forEach((role) => {
            sidebar.classList.remove(role);
            nav.classList.remove(role);
          });
          if(!!sidebar.classList && !!currentRole){
            sidebar.classList.add(currentRole);
            nav.classList.add(currentRole);
          }
        }
      },
      GetCurrentRole(){
        let currentRole = '';

        if(this.$route.name.includes('admin/')){
          currentRole = 'admin';
        } else {

          switch(this.$route.name){
            case 'createevent':
            case 'editevent':
            case 'myevent':
            case '6enter':
              currentRole = 'bookmaker';
              break;
            case 'vote':
            case 'myvote':
            case 'votedetail':
            case 'myreport':
              currentRole = 'oracle';
              break;
            case 'bet':
            case 'mybet':
            case 'eventdetail':
            case 'dashboard':
            case 'leaderboard':
            case 'mysubscription':
            case 'betleague':
              currentRole = 'bettor';
              break;
          }
        }
        return currentRole;
      },
      IsAdminPage(){
        return this.IsAdmin && this.GetCurrentRole() === 'admin';
      },
      Distinct(arr, prop) {
          return arr.map(function(e) { return e[prop]; }).filter(function(e,i,a){
            return i === a.indexOf(e);
        });
      },
      GetEventCardTitle(event){
        let title = event.nation ? event.nation.name + ' / ' : '';

        title += (event.subcategory && event.subcategory.name == 'Unlisted')
          ? event.title
          : event.subcategory ? event.subcategory.name : '';
          
        return title;
      },
      GetEventStatus(status, isText) {
        let result = isText ? this.$t('status.close') : 'CLOSE';
        if (this.IsAdminPage()) {
          result = status == 0
            ? isText ? this.$t('status.open') : 'OPEN'
            : isText ? this.$t('status.close') : 'CLOSE';
        } else {
          switch (status) {
            case 0:
              result = isText ? this.$t('status.open') : 'OPEN';
              break;
            case 1:
              result = isText ? this.$t('status.voting') : 'VOTING';
              break;
            case 2:
              result = isText ? this.$t('status.appeal') : 'APPEAL';
              break;
            case 3:
              result = isText ? this.$t('status.close') : 'CLOSE';
              break;        
            default:
          }
        }
        return result;
      },
      GetGameStatus(status, isText){
        let result = isText ? this.$t('status.close') : 'CLOSE';
        switch (status) {
          case 1:
            result = isText ? this.$t('status.open') : 'OPEN';
            break;
          case 2:
          case 3:
            result = isText ? this.$t('status.voting') : 'VOTING';
            break;
          case 4:
          case 5:
          case 6:
            result = isText ? this.$t('status.appeal') : 'APPEAL';
            break;
          case 7:
            result = isText ? this.$t('status.close') : 'CLOSE';
            break;        
          default:
        }
        return result;
      },
      GetModalFooter(text){
        return '<ul class="fa-ul"><li class="pt-2 text-warning text-small"><span class="fa-li"><i class="fas fa-info-circle"></i></span> '+ text +'</li></ul>';
      },
      GetTokenName(token){
        var tokenName = token;
        var selected = this.Tokens.filter(t => t.value == token);
        if(selected.length > 0){
          tokenName = selected[0].text;
        }
        return tokenName;
      },
      GetContractAddress(token){
        var tokenName = this.GetTokenName(token);
        return this.Web3Info.contractAddresses.filter(address=> address.token == tokenName)[0].address;
      },
      GoVoteDetail(eventId){
        this.$router.push({path: '/votedetail/'+ eventId});
      },
      GetFloorValue(value, decimalPlaces){
        let divisor = Math.pow(10, decimalPlaces);
        return Math.floor(parseFloat(value) * divisor) / divisor;
      },
      GetAmericanOdds(decimal){
        if(decimal <= 1){
          return 'NaN';
        }
        var americanOdds = oddslib.from('decimal', decimal).to('moneyline');
        return (americanOdds >= 0 ? '+' : '') + (americanOdds.toFixed());
      },
      GetDecimalOdds(odds){
        odds = typeof odds !== Number ? Number(odds) : odds;
        let userOddsFormat = this.GetUserOddsFormat();
        let decimalOdds = userOddsFormat == '1' ? odds : oddslib.from('moneyline', odds).to('decimal');
        return decimalOdds;
      },
      OpenConnectWalletModal(){
        this.$swal({
          title: this.$t('connectMessage'),
          icon: 'info',
          animation: false,
          showCancelButton: true,
          confirmButtonText: this.$t('connect'),
          cancelButtonText: this.$t('cancel')
        }).then((result) => {
          if (result.value) {
            this.ConnectWallet();
          }
        });
      },
      GroupArrayOfObjects(list, key) {
        return list.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      }
  },
  watch:{
    $route (){
      this.SetSidebarColor();
    }
  }
}