<template>
    <div>
        <p><strong>Effective Date:</strong> February 25, 2023</p>
        <p>Welcome to Good Bettor! By accessing or using our website, you agree to these Terms of Service, which govern your use of our platform.</p>
        <h2>Eligibility</h2>
        <p>Good Bettor only provides its services to individuals who are over the legal age for gambling, and in countries where gambling is permitted. If we suspect that you do not meet these legal requirements, you will not be permitted to use our service.</p>
        <h2>Account Registration</h2>
        <p>You are responsible for maintaining the confidentiality of your account information and ensuring that all information provided during registration is accurate and complete. You are solely responsible for all activities that occur under your account.</p>
        <h2>Subscription</h2>
        <p>All subscriptions are the responsibility of the member. If a payment has been missed, you will be removed from our listings until payment has been made. It is the responsibility of the member to notify us when payment has been completed.</p>
        <h2>Prohibited Conduct</h2>
        <p>We will not accept any abusive behavior towards our staff or other users of the platform. Anyone engaging in such behavior will be given a warning outlining the possibility of immediate expulsion from all our services and products. If the abusive behavior continues, you will be removed from our listings immediately with no refunds given.</p>
        <h2>Limitation of Liability</h2>
        <p>Good Bettor does not guarantee the accuracy or reliability of any information on the platform, and shall not be responsible for any losses incurred as a result of your use of the platform.</p>
        <h2>Termination</h2>
        <p>We reserve the right to terminate your account at any time if you violate these Terms of Service or engage in any other prohibited conduct.</p>
        <h2>Governing Law and Jurisdiction</h2>
        <p>These Terms of Service shall be governed by and construed in accordance with the laws of Ontario, Canada. Any disputes arising under these Terms of Service shall be resolved exclusively by the courts of Ontario.</p>
        <h2>Entire Agreement</h2>
        <p>These Terms of Service constitute the entire agreement between you and Good Bettor, and supersede all prior agreements or understandings, whether written or oral, regarding the platform.</p>
        <p>By accessing or using the Good Bettor platform, you acknowledge that you have read, understood, and agreed to these Terms of Service. If you do not agree to these Terms of Service, you may not access or use the platform.</p>
    </div>
</template>

<script>
export default {
  name: 'terms',
}
</script>