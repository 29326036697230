<template>
  <b-sidebar v-model="showDialogue" id="betslip" :title="$t('betslip')" right bg-variant="dark" text-variant="light" @shown="getUpdatedAvailableMaxStake">
    <perfect-scrollbar>
    <div class="px-3 py-2 betslip">
      <div v-if="bets.length > 0">

        <div v-for="bet in bets" :key="bet.gameOptionId" class="grid-margin stretch-card">
          <div class="card aligner-wrapper">
            <div class="card-body">
              <div :class="['absolute','left','top','bottom','h-100','v-strock-2', (!bet.enableBet || bet.errorBetAmount) ? 'bg-danger' : 'bg-success']"></div>
              
              <h6 v-if="(!bet.enableBet || bet.errorBetAmount) && bet.message != ''" class="text-danger small">
                ! {{ bet.message }}
              </h6>

              <div class="d-flex flex-row justify-content-between">
                  <div class="text-muted mb-1 text-small"><i :class="GetCategoryIcon(bet.category)" v-b-tooltip.html.top :title="bet.category" /> {{ bet.event.title }}</div>
                  <div class="align-self-center">
                    <b-button variant="inverse-danger" class="btn-icon" @click="removeItem(bet.gameOptionId)">
                     <i class="mdi mdi-close"></i>
                    </b-button>
                  </div>
              </div>

              <small class="text-muted">{{ bet.gameTitle }} </small>
              <div class="d-flex align-items-center text-info">
                <i class="far fa-check-circle ico-game-option"></i>
                <h6 class="ml-2">{{bet.gameOptionTitle}}</h6>
                <h6 class="ml-2" v-if="bet.point">({{bet.point}})</h6>
              </div>
              
              <div class="row mt-1"> 
                  <div class="col-5">
                    <span :class="['odds', OptionOddsClassPrefix + bet.gameOptionId, getOddsChangeClass(bet.oddsChangeStatus)]"><odds-value :odds="bet.odds" /></span>
                    <i :class="['mdi', OptionOddsArrowClassPrefix + bet.gameOptionId, getOddsChangeArrowClass(bet.oddsChangeStatus)]"></i>
                  </div>
                  
                  <div class="col-7">
                    <b-button :class="['aceept-odds-change','btn-sm', OptionOddsChangeButtonClassPrefix + bet.gameOptionId, {'hidden':!bet.enableAcceptOddsChange}]"
                        variant="warning"
                        @click.prevent="acceptOddsChange(bet)"
                        block>{{ $t('acceptOddsChange') }}</b-button>
                    
                    <b-form-group :class="[OptionStakeClassPrefix + bet.gameOptionId, {'hidden':bet.enableAcceptOddsChange}]" 
                      v-b-tooltip.html :title="bet.stakeTooltip">

                      <b-input-group :prepend="CurrentCurrency" :class="['stake', {'warning' : bet.errorBetAmount}]" size="sm">
                        <b-form-input v-model="bet.stake" type="number" class="stake-value betslip-stake" @change="onStakeChange(bet)" />
                      </b-input-group>
                    </b-form-group>
                  </div>
              </div>

            </div>
          </div>
        </div>

        <div class="row mt-2 mb-2">
          <div class="col-12 align-items-center text-sm text-muted">
            <h6 class="float-left">{{ $t('totalBet') }}</h6>
            <h6 class="float-right">{{getTotalStake()}} {{CurrentCurrency}}</h6>
          </div>

          <div class="col-12 align-items-center text-sm text-warning">
            <h6 class="float-left">{{ $t('potentialWin') }}</h6>
            <h6 class="float-right">{{getTotalPotentialWin()}} {{CurrentCurrency}}</h6>
          </div>
        </div>
                      
        <b-button v-if="IsLoggedIn" id="betslip-placebet" class="btn-lg text-uppercase" variant="success" block @click.prevent="placeBet" :disabled="!enablePlaceBet" >{{ $t('placeBet') }}</b-button>
        <b-button v-else class="btn-lg" variant="warning" type="button" block v-b-modal.signin-modal>{{ $t('signin') }}</b-button>

        <b-button class="clear-betslip mt-3 pb-3" variant="link" block @click="clean">
          <i class="far fa-trash-alt btn-icon-prepend"></i> {{ $t('clearBetslip') }}
        </b-button>

        <vue-snotify />
      </div>

      <div v-else-if="!betSuccess" class="no-item">
        <i class="mdi mdi-basket-fill text-info"></i>
        <h3 class="mb-2 text-warning">{{ $t('placeYourBets') }}</h3>
        <h6>{{$t('placeBetMessage')}}</h6>
      </div>

      <div class="bet-success" v-show="betSuccess">
        <i class="mdi mdi-check-circle-outline text-success"></i>
        <h4 class="mb-2 pb-4" v-html="$t('successPlaceBet')"></h4>
        <b-button variant="success" class="btn-lg" @click="closeSuccessMessage">{{ $t('ok') }}</b-button>
      </div>
    </div>
    </perfect-scrollbar>
  </b-sidebar> 
</template>

<script>
import oddsValue from '../../pages/share/odds-value';

export default {
  components: {
    oddsValue
  },
  data (){
    return {
      showDialogue: false,
      betSuccess: false,
      betslipStorage: [],
      bets: [],
      enablePlaceBet: true,
      defaultStake: 10,
      isAddToBetslip: false,
      timer: null
    }
  },
  computed: {
    totalOdd: function(){
      let oddTotal = this.bets.reduce(function(prev, cur){
        return (parseFloat(prev)*10 + parseFloat(cur.selectedOdds)*10) / 10;
      }, 0);
      return oddTotal;
    }
  },
  beforeDestroy: function(){
    this.$root.$off('addToBetslip');
    this.$root.$off('updateEventEnablement');
    this.$root.$off('updateBetStatus');
    this.$root.$off('updateOptionStatus');
    clearInterval(this.timer);
    this.timer = null;
  },
  mounted(){
    if (this.IsLoggedIn) {
      this.getBetslip();
    }
    this.$root.$on('addToBetslip', (bet) => {
      this.showDialogue = true;
      if(bet.addToBetslip){
        this.addItem(bet);
      } else {
        this.removeItem(bet.gameOptionId);
      }
    });

    this.$root.$on('updateEventEnablement', evt => {
      if(this.bets.length > 0){
        evt.gameOptions.forEach((option) => {
          let bet = this.bets.find(b => b.gameOptionId == option.id);
          if(bet) {
            bet.event.enableBet = evt.enableBet;
            this.updateBetStatus(bet, option);
          }
        });
      }
    });
    
    this.$root.$on('updateBetStatus', (option) => {
      if(this.bets.length > 0){
        let bet = this.bets.find(b => b.gameOptionId == option.id);
        this.updateBetStatus(bet, option);
      }
    });

    this.$root.$on('updateOptionStatus', (option) => {
        let changeStatus = option.odds > option.previousOdds
          ? 'up'
          : (option.odds < option.previousOdds ? 'down' : '');

        let elements = [...document.getElementsByClassName(this.OptionOddsClassPrefix + option.id)];
        elements.forEach((element) => {
          element.classList.remove('odds-change-up','odds-change-down');
          element.classList.add('odds-change-' + changeStatus);
        });

        let arrowElements = [...document.getElementsByClassName(this.OptionOddsArrowClassPrefix + option.id)];
        arrowElements.forEach((element) => {
          element.classList.remove('hidden','mdi-arrow-up-bold','mdi-arrow-down-bold','odds-change-up','odds-change-down');
          element.classList.add('mdi-arrow-' + changeStatus + '-bold', 'odds-change-' + changeStatus);
        });

        let acceptButtonElements = [...document.getElementsByClassName(this.OptionOddsChangeButtonClassPrefix + option.id)];
        acceptButtonElements.forEach((element) => {
          element.classList.remove('hidden');
        });

        let stakeElements = [...document.getElementsByClassName(this.OptionStakeClassPrefix + option.id)];
        stakeElements.forEach((element) => {
          element.classList.add('hidden');
        });

        let item = this.bets.find(bet => bet.gameOptionId == option.id); 
        if(item) {
          item.previousOdds = option.previousOdds;
          item.odds = option.odds;
          item.point = option.point;
          item.oddsChangeStatus = changeStatus;
          item.enableAcceptOddsChange = true;
          this.updateStorageValue(item);
        }
    });

    this.$bobwinHub.$on('betSaved', (response) => {
      if(response.result === 'success') {
        let user = JSON.parse(response.userData);
        this.$store.dispatch('setUser', user);

        let successAll = false;
        let result = JSON.parse(response.data);        
        result.forEach((bet) => {
          if(bet.saveBetResult == 1){
            this.removeItem(bet.gameOptionId);
            successAll = true;
          } else {
            let item = this.bets.find(bet => bet.gameOptionId == bet.gameOptionId); 
            item.stake = this.getStake(bet);
            item.enableBet = false;
            item.message = this.$t('betNotAvailable');
            item.stakeTooltip = this.$t('availableMaxAmount', { amount: this.getStake(bet) });
            this.updateStorageValue(item);
            successAll = successAll && false;
          }
        });
        if(successAll){
          this.$root.$emit('betsucceed');
          this.betSuccess = true;
          this.clean();
        }
      }
      else {
        this.ShowErrorMessage(response.message);
      }
    });

    this.$bobwinHub.$on('updatedMaxStakeReceived', (response) => {
      if(response.result === 'success') {
        let results = JSON.parse(response.data);
        results.forEach(detail => {
          let bet = this.bets.find(o => o.gameOptionId == detail.gameOptionId);
          bet.availableMaxStake = detail.availableMaxStake;
          bet.stakeTooltip = this.$t('availableMaxAmount', {amount: detail.availableMaxStake});
        });
      }
    });

    this.$bobwinHub.$on('betslipOptionDetailsReceived', (response) => {
      if(response.result === 'success') {
        let results = JSON.parse(response.data);
        if(results){
          results.forEach(detail => {
            let storageOption = this.betslipStorage.find(op => op.gameOptionId == detail.gameOptionId);
            if(storageOption){
              let bet = {
                gameOptionId: storageOption.gameOptionId,
                enableBet: storageOption.enableBet,
                odds: storageOption.odds,
                point: storageOption.point,
                oddsChangeStatus: storageOption.oddsChangeStatus,
                enableAcceptOddsChange: storageOption.enableAcceptOddsChange,
                stake: storageOption.stake,
                gameTitle: detail.gameTitle,
                gameOptionTitle: detail.gameOptionTitle,
                seedMoney: detail.seedMoney,
                gameBetAmount: detail.gameBetAmount,
                category: detail.event.eventCategoryName,
                message: detail.message,
                event: detail.event,
                useSharedSeedMoney: detail.useSharedSeedMoney,
                availableMaxStake: detail.availableMaxStake
              };
              bet.stakeTooltip = this.$t('availableMaxAmount', {amount: this.getAvailableMaxStake(bet)});
              bet.event.closed = false;
              if(this.isAddToBetslip){
                let stake = this.getStake(bet);
                bet.stake = stake;
                storageOption.stake = stake;
                this.updateStorageValue(storageOption);
                this.isAddToBetslip = false;
              }
              this.bets.push(this.updateBetAvailable(bet, detail));
            }
          });
          this.updatePlaceBetButton();
          this.runEventStatusUpdater();
        }
      }
    });
  },
  methods: {
    getUpdatedAvailableMaxStake(){
      let sharedSeedMoneyOptions = this.bets.filter(bet => bet.useSharedSeedMoney);
      let gameOptionIds = this.Distinct(sharedSeedMoneyOptions, 'gameOptionId');
      if(gameOptionIds.length > 0){
        let param = { GameOptionIds: gameOptionIds };
        this.CallHub({task: 'GetBetslipGameOptionDetails', callback: 'updatedMaxStakeReceived', data: JSON.stringify(param)});
      }
    },
    getBetslip(){
      this.betslipStorage = this.GetBetslipList();
      let gameOptionIds = this.Distinct(this.betslipStorage, 'gameOptionId');
      this.getBetslipDetails(gameOptionIds);
    },
    getBetslipDetails(gameOptionIds){
      let param = { GameOptionIds: gameOptionIds };
      this.CallHub({task: 'GetBetslipGameOptionDetails', callback: 'betslipOptionDetailsReceived', data: JSON.stringify(param)});
    },
    getOddsChangeClass(oddsChangeStatus){
      return oddsChangeStatus != '' ? ' odds-change-'+ oddsChangeStatus : '';
    },
    getOddsChangeArrowClass(oddsChangeStatus){
      let colorClass = this.getOddsChangeClass(oddsChangeStatus);
      let arrowClass = oddsChangeStatus != '' ? ' mdi-arrow-'+ oddsChangeStatus +'-bold' : ' hidden';
      return arrowClass + colorClass;
    },
    getStake(bet){
      let availableMaximumStake = this.getAvailableMaxStake(bet);
      return availableMaximumStake > this.defaultStake ? this.defaultStake : availableMaximumStake;
    },
    getAvailableMaxStake(bet){
      let result = 0;
      if(bet.useSharedSeedMoney){
        result = bet.availableMaxStake;
      }
      else {
        let stake = (bet.seedMoney - bet.gameBetAmount) / bet.odds;
        result = this.GetFloorValue(stake, 2);
      }
      return result;
    },
    getTotalStake(){
      let total = 0;
      let stake = 0;
      this.bets.forEach((bet)=> {
        stake = bet.stake != null ? parseFloat(bet.stake) : 0;
        total += stake;
      });
      return total.toFixed(2);
    },    
    getTotalPotentialWin(){
      let total = 0;
      this.bets.forEach((bet)=> {
        total += this.calculatePotentialWin(bet.stake, bet.odds);
      });
      return total.toFixed(2);
    },
    calculatePotentialWin(stake, odds){
        let stakeValue = stake != null ? parseFloat(stake) : this.defaultStake;
        return ((parseFloat(odds) * 10) * (parseFloat(stakeValue) * 10)) / 100;
    },
    onStakeChange(bet){
      let availableMaxStake = this.getAvailableMaxStake(bet);
      if(bet.stake <= 0 && bet.enableBet) {
        bet.errorBetAmount = true;
        bet.message = this.$t('stakeConditionMessage');
      } else if (bet.stake > availableMaxStake) {
        bet.errorBetAmount = true;
        bet.message = this.$t('availableMaxAmount', {amount: availableMaxStake});
      } else {
        bet.errorBetAmount = false;
        bet.message = bet.enableBet ? '' : bet.message;
      }
      this.updateStorageValue(bet);
    },
    updateBetStatus(bet, option){
      if(bet){
        bet.enableBet = option.enableBet;
        bet.message = option.message;
        this.updateStorageValue(bet);
      }
    },
    updateBetAvailable(bet, option){
      let updatedBet = bet;
      if(this.getAvailableMaxStake(bet) <= 0){
        updatedBet.enableBet = false;
        updatedBet.message = this.$t('betNotAvailable');
      }
      else if(bet.odds != option.odds.toString()){
        updatedBet.enableBet = false;
        updatedBet.enableAcceptOddsChange = true;
        updatedBet.message = this.$t('oddsChanged');
      }
      else {
        updatedBet.enableBet = option.enableBet;
        updatedBet.message = option.message;
      }
      this.onStakeChange(updatedBet);

      return updatedBet;
    },
    addItem(bet){
      this.betSuccess = false;
      if(!this.betslipStorage.find(item => item.gameOptionId == bet.gameOptionId)){
        this.isAddToBetslip = true;

        bet.stake = this.defaultStake;
        bet.oddsChangeStatus = '';
        this.betslipStorage.push(bet);

        this.updateStorage().then(() => {
          let gameOptionIds = [bet.gameOptionId];
          this.getBetslipDetails(gameOptionIds);
          this.UpdateBetButton(bet.gameOptionId);
        });
      }
    },
    removeItem(gameOptionId){
      let index = this.GetBetslipIndex(gameOptionId);
      if(index > -1){
        this.$delete(this.betslipStorage, index);
        this.$delete(this.bets, index);

        this.updateStorage().then(() => {
          this.UpdateBetButton(gameOptionId);          
          this.updatePlaceBetButton();
        });
      }
    },
    clean(){
      let previousBets = this.betslipStorage;
      this.bets = [];
      this.betslipStorage = [];
      this.updateStorage().then(()=>{ 
        previousBets.forEach((bet) => {
          this.UpdateBetButton(bet.gameOptionId);
        });  
        this.updatePlaceBetButton() ;
      });
    },
    acceptOddsChange(bet){
      bet.enableAcceptOddsChange = false;
      this.onStakeChange(bet);
      this.resetChangeStatus(bet.gameOptionId);
    },
    updateStorageValue(bet){
      if(bet) {
        let item = this.betslipStorage.find(s => s.gameOptionId == bet.gameOptionId);
        if(item){
          item.previousOdds = bet.previousOdds;
          item.point = bet.point;
          item.odds = bet.odds;
          item.stake = bet.stake;
          item.oddsChangeStatus = bet.oddsChangeStatus;
          item.enableAcceptOddsChange = bet.enableAcceptOddsChange;
        }
        this.updateStorage().then(()=>{ this.updatePlaceBetButton() });
      }
    },
    async updateStorage(){
      localStorage.setItem('betslip', JSON.stringify(this.betslipStorage));
    },
    updatePlaceBetButton(){
      let results = this.bets.filter(item => item.enableAcceptOddsChange);
      let disabledBet = this.bets.filter(option => !option.enableBet);
      let errorBetAmount = this.bets.filter(option => option.errorBetAmount);
      this.enablePlaceBet = (results.length === 0 && disabledBet.length === 0 && errorBetAmount.length === 0);
    },  
    placeBet(){
      this.$swal({
        icon: 'info',
        title: this.$t('confirmPlaceBet'),
        animation: false,
        showCancelButton: true,
        confirmButtonText: this.$t('ok'),
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        if (result.value) {
          let betList = [];
          this.bets.forEach((bet) => {
            betList.push(
              {
                userId: this.UserId,
                amount: bet.stake,
                odds: bet.odds,
                gameOptionId: bet.gameOptionId
              });
          });
          this.CallHub({task: 'SaveBet', callback: 'betSaved', data: JSON.stringify(betList)});
        }
      });
    },
    closeSuccessMessage(){
      this.betSuccess = false;
    },
    runEventStatusUpdater(){
      this.timer = setInterval( () => {
        this.bets.forEach((bet) => {
          if(!bet.event.closed && this.$moment(this.$store.state.bobUTCTime).isAfter(this.$moment.utc(bet.event.endDate))){
            bet.event.closed = true;
            let option = {
              id: bet.gameOptionId,
              enableBet: false,
              message: this.$t('betClosed')
            };            
            this.$root.$emit('updateBetStatus', option);
          }
          if(!bet.event.enableBet && this.$moment(this.$store.state.bobUTCTime).isAfter(this.$moment.utc(bet.event.startDate))){
            let option = {
              id: bet.gameOptionId,
              enableBet: false,
              message: this.$t('betDisabled')
            };
            this.$root.$emit('updateBetStatus', option);
          }

        });
      }, 3000 );
    },
    resetChangeStatus(optionId){
      let elements = [...document.getElementsByClassName(this.OptionOddsClassPrefix + optionId)];
      elements.forEach((element) => {
        element.classList.remove('odds-change-up','odds-change-down');
      });

      let arrowElements = [...document.getElementsByClassName(this.OptionOddsArrowClassPrefix + optionId)];
      arrowElements.forEach((element) => {
        element.classList.remove('hidden','mdi-arrow-up-bold','mdi-arrow-down-bold','odds-change-up','odds-change-down');
      });
    }
  }
  
}
</script>

<style lang="scss" scoped>
.badge {
    height: 15px;
    color: #fff;
    text-align: center;
    font-size: .625rem;
    line-height: 0.1;
    padding: .5rem .3em;
}
.form-group {
    margin-bottom: 0;
}
.text-tranparency {
  opacity: 0.4;
}
.btn-icon {
  width: 20px;
  height: 20px;
}
.total-bet p {
  margin-bottom: 0.5rem;
}
.card-body div span {
  font-size: 1rem;
}
.bet-success {
  text-align: center;
  i {
    font-size: 100px;
  }
}
.card .card-body {
  padding: 0.7rem 1rem 0.7rem 1.2rem;
}
.clear-betslip i {
  font-size: 0.9rem;
}
.ico-game-option {
  margin-top: -0.325rem;
}
.btn-link {
  font-size: 0.875rem !important;
}
</style>