<template>
  <section class="forgot-password">
    <b-modal id="forgot-password" class="forgot-password" :title="$t('forgotPassword')" size="md" button-size="lg" hide-footer @hidden="init">
      <div v-if="processing">
        <div class="pixel-loader"></div>
      </div>
      <div v-else>
        <div class="auth-form-transparent text-left p-3">
            <b-form @submit="request">
                <b-form-group :label="$t('email')" label-for="email">
                    <b-form-input v-model="email" id="email" type="email" class="form-control" :placeholder="$t('email')" :state="state" :disabled="processing"  required />
                </b-form-group>

                <div class="text-danger mb-3" v-if="state === false">! {{ errorMessage }}</div>

                <div class="mt-3">
                    <b-button type="submit" variant="primary" class="btn-block btn-lg font-weight-medium" :disabled="processing" >{{ $t('requestReset').toUpperCase() }}</b-button>
                </div>
            </b-form>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'forgot-password',
  data(){
    return {
        email: '',
        state: null,
        errorMessage: '',
        processing: false
    }
  },
  methods: {
    init(){
      this.email = '';
      this.state = null;
      this.errorMessage = '';
      this.processing = false;
    },
    request(event){
      event.preventDefault();
      this.processing = true;
      axios.post('/api/Users/forgotpassword', {
        Email: this.email
      })
      .then(response => {
          this.$bvModal.hide('forgot-password');
          this.processing = false;
        if (response.data?.result?.result === "success") {
          this.ShowSuccessMessage(this.$t('checkemail'));
        } else {
          this.ShowErrorMessage(response.data?.result?.message);
        }
      })
      .catch(error => {
        this.state = false;
        this.processing = false;
        window.console.log(error);
      });
    }
  }
}
</script>