<template>
    <div>
        <h1>Good Bettor - Privacy Policy</h1>
        <p><strong>Effective Date:</strong> February 25, 2023</p>
        <p>Good Bettor ("us", "we", or "our") operates www.goodbettor.com (the "Site"). This Privacy Policy governs the manner in which we collect, use, maintain and disclose information collected from users (each, a "User") of the Site. This privacy policy applies to the Site and all products and services offered by Good Bettor.</p>
        <h2>Personal Identification Information</h2>
        <p>We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, subscribe to the newsletter, fill out a form, and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number, credit card information. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personal identification information, except that it may prevent them from engaging in certain Site-related activities.</p>
        <h2>Management of Personal Data</h2>
        <p>We make every effort to put in place suitable precautions to safeguard the security and privacy of personal data. Users have the right to access, correct and delete personal data relating to them at any time. Payment information is processed through a third-party provider and we do not store any credit/debit card details.</p>
        <h2>Information Collection and Use</h2>
        <p>We may collect and use Users' personal information for the following purposes:</p>
        <ul>
          <li>To improve our Site: We continually strive to improve our website offerings based on the information and feedback we receive from you.</li>
          <li>To improve customer service: Your information helps us to more effectively respond to your customer service requests and support needs.</li>
          <li>To send periodic emails: The email address Users provide will be used to send them information and updates pertaining to their subscription or request. It may also be used to respond to their inquiries, and/or other requests or questions. If Users decide to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email or Users may contact us via our website.</li>
        </ul>
        <h2>Disclosure of Personal Information to Third Parties</h2>
        <p>We do not sell, trade, or rent users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.</p>
        <h2>Third-Party Websites</h2>
        <p>Users may find advertising or other content on our Site that link to the sites and services of our partners, suppliers, advertisers, sponsors, licensors and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to our Site, is subject to that website's own terms and policies.</p>
        <h2>Changes to This Privacy Policy</h2>
        <p>Good Bettor has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</p>
        <h2>Your Acceptance of These Terms</h2>
        <p>By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.</p>
        <h2>Contacting Us</h2>
        <p>If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at support@goodbettor.com.</p>
    </div>
</template>

<script>
export default {
  name: 'privacy',
}
</script>