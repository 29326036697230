<template>
  <section class="feedback">

    <b-modal id="feedback-modal" class="feedback-modal" :title="$t('feedback')" size="md" button-size="lg" @ok="handleOk" :ok-disabled="processing">
      <div class="p-3">
        <div class="pt-0 pb-3">{{$t('feedbackMessage')}}</div>
        <b-form-textarea v-model="comment" :class="['form-control','form-control-lg', hasError ? 'error' : '']" :placeholder="$t('feedbackPlaceholder')" required autocomplete="off" rows="3" max-rows="6" />
        <h6 class="text-danger p-2" v-if="hasError">! {{ errorMessage }}</h6>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  name: 'feedback',
  data(){
      return {
          comment: '',
          hasError: '',
          errorMessage: '',
          processing: false,
      }
  },
  created () {
      this.$root.$on('openFeedbackDialog', this.openDialog);
      this.$bobwinHub.$on('feedbackAdded', this.feedbackAdded);
      this.processing = false;
  },
  beforeDestroy () {
    this.$root.$off('openFeedbackDialog', this.openDialog);
    this.$bobwinHub.$off('feedbackAdded', this.feedbackAdded);
  },
  methods: {
      handleOk(bvModalEvt){
        bvModalEvt.preventDefault();
        if (this.comment !== "" && this.comment.length !== 0) {
          this.processing = true;
          var param = {
            UserId: "00000000-0000-0000-0000-000000000000",
            Message: this.comment,
          }
          this.CallHub({task: 'AddFeedback', callback: 'feedbackAdded', data: JSON.stringify(param)});
        }
      },
      openDialog() {
        this.$bvModal.show('feedback-modal');
      },
      feedbackAdded() {
        this.comment = '';
        this.$bvModal.hide('feedback-modal');
        this.ShowSuccessMessage('');
        this.processing = false;
      }
  }
}
</script>
<style scoped>
.radio-currency {
    width: 100%;
}
 .form-group {
      margin-bottom: 0.2rem;
    }
</style>