<template>
  <section class="export-gem">
    <b-modal id="export-gem-modal" class="export-gem-modal" :title="$t('export')" size="md" button-size="lg" @ok="handleOk" @cancel="init" :ok-disabled="processing">
      <div class="p-3">
        <div class="pt-0 pb-3">{{$t('exportMessage')}}</div>
        <div class="pt-0 pb-3">{{$t('currentBalance')}}: {{ GetFloorValue(User.gem, 4) }}</div>
        <h6 class="text-danger p-2" v-if="hasError">! {{ errorMessage }}</h6>
      </div>
    </b-modal>
  </section>
</template>

<script>

export default {
  name: 'export-gem',
  data(){
      return {
          hasError: '',
          errorMessage: '',
          processing: false,
      }
  },
  created () {
      this.$root.$on('openExportGemDialog', this.openDialog);
      this.$bobwinHub.$on('getUpdated', this.getUpdated);
      this.processing = false;
  },
  beforeDestroy () {
    this.$root.$off('openExportGemDialog', this.openDialog);
    this.$bobwinHub.$off('getUpdated', this.getUpdated);
  },
  methods: {
      handleOk(bvModalEvt){
        bvModalEvt.preventDefault();
        this.processing = true;
        this.CallHub({task: 'ExportGem', callback: 'getUpdated'});
      },
      openDialog() {
        this.$bvModal.show('export-gem-modal');
      },
      init() {
        this.initError();
        this.processing = false;
      },
      initError() {
        this.hasError = false;
        this.errorMessage = '';
      },
      getUpdated(response) {
        if (response.result == "error") {
          this.hasError = true;
          this.errorMessage = response.message == "No user in GoodMyShop" ? this.$t('noAccountInGoodMyShop') : this.$t('contactSupport');
        } else {
          this.$store.state.user.gem = 0;
          this.$bvModal.hide('export-gem-modal');
          this.ShowSuccessMessage('');
          this.init();
        }
      }
  }
}
</script>
<style scoped>
.radio-currency {
    width: 100%;
}
 .form-group {
      margin-bottom: 0.2rem;
    }
</style>