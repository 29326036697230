<template>
    <b-form-group :label="$t('language') + ':'" label-for="language">
      <b-form-select v-model="$i18n.locale" id="language" size="sm" @change="onLanguageChange">
        <option :value="locale.code" v-for="locale in locales" :key="locale.code">
          {{locale.name}}
        </option>
      </b-form-select>
    </b-form-group>
</template>
<script>
import { getSupportedLocales } from "@/util/supported-locales"
export default {
  data: () => ({ locales: getSupportedLocales() }),
  methods: {
    onLanguageChange(){
      localStorage.setItem('language', this.$i18n.locale);
      this.$root.$emit('setSortOption');
    }
  }
}

</script>
