<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Good Bettor',
    // all titles will be injected into this template
    titleTemplate: '%s | Bet Smarter, not Harder with Good Bettor'
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">
@import "../node_modules/@mdi/font/css/materialdesignicons.min.css";
@import "../node_modules/flag-icon-css/css/flag-icon.min.css";
@import "../node_modules/@fortawesome/fontawesome-free/css/all.css"; 
@import "../node_modules/simple-line-icons/css/simple-line-icons.css";
@import "../node_modules/ti-icons/css/themify-icons.css";
@import "../node_modules/sweetalert2/dist/sweetalert2.min.css";
@import "../node_modules/vue-snotify/styles/material.css";
@import "../node_modules/fullcalendar/dist/fullcalendar.css";
@import "../node_modules/c3/c3.min.css";
@import "../node_modules/chartist/dist/chartist.min.css";
@import "../node_modules/vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
@import "../node_modules/@jledentu/vue-finder/dist/vue-finder.css";
@import "../node_modules/vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
@import '../node_modules/vue-select/dist/vue-select.css';
@import "./assets/scss/style";
</style>
