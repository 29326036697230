<template>
  <section class="withdraw">
    <b-modal id="shared-seedmoney-modal" class="shared-seedmoney-modal" size="md" 
        :title="$t('sharedSeedmoney')" :okTitle="$t('close')" ok-only ok-variant="secondary"
        @show="GetUser"
        @hidden="resetModal">
        
        <b-form-group :label="$t('currentBalance')" label-for="balance" class="pt-3">
        <b-input v-model="User.sharedSeedmoney" id="balance" type="number" class="form-control form-control-lg" disabled />
        </b-form-group>

        
        <b-form-group :label="$t('depositAmount')" label-for="amount" class="pt-3" >
          <b-input-group :label="$t('currentBalance')" label-for="balance">
            <b-form-input v-model="amount" type="number" :placeholder="$t('seedmoney')" :class="['form-control','form-control-lg', hasError ? 'error' : '']" @input="initError"></b-form-input>
            <b-input-group-append>
            <b-button variant="primary" class="add text-uppercase" v-on:click="deposit()">{{$t('add')}}</b-button>
            </b-input-group-append>
          </b-input-group>
          <h6 class="text-danger p-2" v-if="hasError">! {{ errorMessage }}</h6>
        </b-form-group>

    </b-modal>
  </section>
</template>

<script>
export default {
data(){
    return {
        amount: 0,
        hasError: false,
        errorMessage: ''
    }
},
mounted () {
    this.$root.$on('openSeedmoneyDialog', () => {
        this.amount = 0;
        this.$bvModal.show('shared-seedmoney-modal');
    });

    this.$bobwinHub.$on('seedmoneyDepositSaved', response => {
      if(response.result === 'success') {
        this.$swal({
            icon: 'success',
            title: this.$t('successTitle'),
            animation: false,
            confirmButtonText: this.$t('ok')
        });
        let user = JSON.parse(response.userData);
        this.$store.dispatch('setUser', user);
        this.$bvModal.hide('shared-seedmoney-modal');
      }
      else {
        this.hasError = true;
        this.errorMessage = response.message;
      }
    });
},
methods: {
    resetModal(){
        this.hasError = false;
        this.errorMessage = '';
    },
    checkStatus(){
        if(this.amount > this.User.balance){
          this.hasError = true;
          this.errorMessage = this.$t('notEnoughBalance');
      }
    },
    initError() {
      this.hasError = false;
      this.errorMessage = '';
    },
    deposit(){
      if(this.amount > this.User.balance){
          this.hasError = true;
          this.errorMessage = this.$t('notEnoughBalance');
      }
      if(this.amount > 0){
        var param = {
          UserId: this.UserId,
          TokenType: 'USDT',
          Amount: this.amount
        }
        this.CallHub({task: 'DepositSharedSeedmoney', callback: 'seedmoneyDepositSaved', data: JSON.stringify(param)});
      }
    }

  }
}
</script>
<style scoped>
.input-group-text {
    width: 60px;
}
 .form-group {
      margin-bottom: 0.2rem;
    }
</style>