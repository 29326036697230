<template>
    <section>
        <ul class="nav flex-column sub-menu">
            <li class="nav-item" v-on:click="showNickname">
            <a class="nav-link" href="#">
                <span class="menu-icon"><i class="mdi mdi-account-card-details" v-b-tooltip :title="$t('nickname')" /></span>
                <span class="menu-title">{{ nickname }}</span>
                <span class="text-muted small pl-2 mt-1"><i class="ti-layers" v-b-tooltip :title="$t('edit')" /></span>
            </a>
            </li>
            <li class="nav-item">
            <a class="nav-link" href="#">
                <span class="menu-icon"><i class="mdi mdi-currency-usd" v-b-tooltip :title="CurrentCurrency"/></span>
                <span class="menu-title" v-b-tooltip :title="GetFloorValue(User.balance, 4)">{{ GetFloorValue(User.balance, 4) }}</span>
                <span class="text-muted small pl-2 mt-1">{{ CurrentCurrency }}</span>
                <div v-if="USDTInProgress" class="ml-2 dot-opacity-loader" v-b-tooltip :title="USDTInProgressMessage"></div>
            </a>
            </li>
            <li class="nav-item">
            <a class="nav-link" href="#">
                <span class="menu-icon"><i class="mdi mdi-google" v-b-tooltip :title="GetFloorValue(UserGold, 4)"/></span>
                <span class="menu-title" v-b-tooltip :title="GetFloorValue(User.gold, 4)">{{ GetFloorValue(User.gold, 4) }}</span>
                <span class="text-muted small pl-2 mt-1">{{ UserGold }}</span>
            </a>
            </li>
            <li class="nav-item" v-on:click="()=> { if (User.gem > 0) showExport()}">
            <a class="nav-link" href="#">
                <span class="menu-icon"><i class="mdi mdi-diamond" v-b-tooltip :title="UserGem"/></span>
                <span class="menu-title" v-b-tooltip :title="GetFloorValue(User.gem, 4)">{{ GetFloorValue(User.gem, 4) }}</span>
                <span class="text-muted small pl-2 mt-1">{{ UserGem }}</span>
                <span class="text-muted small pl-2 mt-1"><i class="ti-layers" v-b-tooltip :title="$t('export')" /></span>
            </a>
            </li>
        </ul>
        <nickname v-if="IsLoggedIn" />
        <export-gem v-if="IsLoggedIn" />
    </section>
</template>

<script>
import Nickname from "../../pages/dialogs/nickname";
import ExportGem from "../../pages/dialogs/exportGem";

export default {
  name: 'account-info',
  components: {
    Nickname,
    ExportGem
  },
  props: ["email", "nickname", "USDTInProgress", "USDTInProgressMessage"],
  methods: {  
    showNickname() {
      this.$root.$emit('openNicknameDialog');
    },
    showExport() {
      this.$root.$emit('openExportGemDialog');
    }
  }
}
</script>