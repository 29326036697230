<template>
  <section class="app-sidebar">
    <div class="sidebar-background" id="sidebar-background" v-on:click="toggleMobileSidebar">
      <nav class="sidebar sidebar-offcanvas" id="sidebar">
        <div class="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top text-center">
          <router-link class="sidebar-brand brand-logo" to="/">
            <img src="@/assets/images/logo_450-340.png" alt="logo" />
          </router-link>
          <router-link class="sidebar-brand brand-logo-mini" to="/">
            <img src="@/assets/images/logo_450-340.png" alt="logo" />
          </router-link>
        </div>
        <ul class="nav" id="nav">
          <li class="nav-item account-dropdown" id='myaccount-dropdown' v-if="IsLoggedIn">
            <a class="nav-link" v-b-toggle="'account-dropdown'">
              <i class="icon-account fas fa-user-astronaut" />
              <p class="ml-3 mb-0">{{ $t('myaccount') }}</p>
              <i class="menu-arrow"></i>
            </a>

            <b-collapse accordion="sidebar-accordion" id="account-dropdown">
              <account-info :nickname="nickname" :email="email" :USDTInProgress="USDTInProgress" :USDTInProgressMessage="USDTInProgressMessage" />
              <ul class="nav flex-column sub-menu">
                <li class="nav-item" id="myaccount-deposit" v-on:click="deposit">
                  <a class="nav-link" href="#">
                    <span class="menu-icon"><i class="mdi mdi-bank-transfer-in" v-b-tooltip :title="$t('deposit')" /></span>
                    <span class="menu-title">{{ $t('deposit') }}</span>
                    <span class="text-muted small pl-2 mt-1"><i class="ti-layers" v-b-tooltip :title="$t('deposit')" /></span>
                  </a>
                </li>
  <!--              <li class="nav-item" id="myaccount-purchase-gold" v-on:click="purchaseGold">
                  <a class="nav-link" href="#">
                    <span class="menu-icon"><i class="mdi mdi-bank-transfer-in" v-b-tooltip :title="$t('deposit')" /></span>
                    <span class="menu-title">Gold</span>
                  </a>
                </li>-->
                <li class="nav-item" v-on:click="feedback">
                  <a class="nav-link" href="#">
                    <span class="menu-icon"><i class="mdi mdi-comment-processing-outline" v-b-tooltip :title="$t('feedback')" /></span>
                    <span class="menu-title">{{ $t('feedback') }}</span>
                    <span class="text-muted small pl-2 mt-1"><i class="ti-layers" v-b-tooltip :title="$t('add')" /></span>
                  </a>
                </li>
                <li class="nav-item" v-on:click="Logout">
                  <a class="nav-link" href="#">
                    <span class="menu-icon"><i class="mdi mdi-logout-variant" v-b-tooltip :title="$t('logout')" /></span>
                    <span class="menu-title">{{ $t('logout') }}</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-item menu-items">
            <span class="nav-link" v-b-toggle="'bettor-dropdown'" :class="{'active': subIsActive(['bet', 'mybet', 'dashboard', 'leaderboard', 'mysubscription'])}">
              <span class="menu-icon">
                <i class="mdi mdi-medical-bag text-warning"></i>
              </span>
              <span class="menu-title">{{ $t('bettor') }}</span>
              <i class="menu-arrow"></i>
            </span>
            <b-collapse accordion="sidebar-accordion" id="bettor-dropdown" :visible="subIsActive(['bet', 'mybet', 'dashboard', 'leaderboard', 'mysubscription'])">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item" v-if="IsLoggedIn">
                  <router-link class="nav-link text-muted" to="/dashboard">{{ $t('myDashboard') }}</router-link>
                </li>
                <li class="nav-item" v-if="IsLoggedIn">
                  <router-link class="nav-link text-muted" to="/mybet">{{ $t('myBets') }}</router-link>
                </li>
                <li class="nav-item" v-if="IsLoggedIn">
                  <router-link class="nav-link text-muted" to="/mysubscription">{{ $t('mySubscription') }}</router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-item menu-items">
            <span class="nav-link" v-b-toggle="'setting-dropdown'">
              <span class="menu-icon">
                <i class="fas fa-cog text-more-muted"></i>
              </span>
              <span class="menu-title">{{ $t('settings') }}</span>
              <i class="menu-arrow"></i>
            </span>
            <b-collapse accordion="sidebar-accordion" id="setting-dropdown">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  <b-form-group :label="$t('oddsFormat') + ':'" label-for="odds-format">
                    <b-form-select id="odds-format" v-model="oddsFormat" :options="OddsFormats" size="sm" @change="onOddsFormatChange"></b-form-select>
                  </b-form-group>
                </li>

                <li class="nav-item">
                  <locale-switcher />
                </li>
              </ul>
            </b-collapse>
          </li>        
        </ul>

        <div class="sidebar-footer-wrapper align-items-center justify-content-center text-center">
          <div class="block mb-2">
          </div>
          <div>
            <b-button v-for="social in socialList" v-bind:key="social" class="btn-social-icon btn-rounded border-0 mr-2" @click="openSocial(social)"  v-b-tooltip :title="social">
            <i :class="'mdi mdi-'+ (social == 'kakao' ? 'forum' : social)"></i>
            </b-button>
          </div>
        </div>
      </nav>
      <deposit v-if="IsLoggedIn" />
      <Feedback v-if="IsLoggedIn" />
      <token-purchase v-if="IsLoggedIn" />
      <gold-purchase v-if="IsLoggedIn" />
      <shared-seedmoney v-if="IsLoggedIn" />
    </div>
  </section>
</template>

<script>
import Deposit from "../../pages/dialogs/deposit-playmoney";
import Feedback from "../../pages/dialogs/feedback";
import TokenPurchase from "@/pages/dialogs/token-purchase";
import GoldPurchase from "@/pages/gold/purchase-dialog";
import SharedSeedmoney from "@/pages/dialogs/shared-seedmoney";
import LocaleSwitcher from "@/components/LocaleSwitcher";
import AccountInfo from '../../pages/share/account-info.vue';

export default {
  name: 'sidebar',
  components: {
    Deposit,
    Feedback,
    TokenPurchase,
    GoldPurchase,
    SharedSeedmoney,
    LocaleSwitcher,
    AccountInfo 
  },
 data () {
    return {
      collapses: [ 
        { show: false },
        { show: false },
        { show: false }
      ],
      socialList: ['instagram','facebook','twitter','kakao'],
      USDTInProgress: false,
      USDTInProgressMessage: '',
      CIBInProgress: false,
      CIBInProgressMessage: '',
      oddsFormat: 1
    }
  },
  computed: {
    email(){
      return (this.User && this.User.email != '') ? this.emailMask(this.User.email) : '';
    },
    nickname() {
      return (this.User && this.User.nickname != '') ? this.User.nickname : this.email;
    }
  },
  created (){
    this.$root.$on('updateDepositStatus', depositStatus => {
      if(depositStatus.tokenType == '2'){
          this.USDTInProgress = depositStatus.status;
          this.USDTInProgressMessage = depositStatus.message;
        }
        else {
          this.CIBInProgress = depositStatus.status,
          this.CIBInProgressMessage = depositStatus.message;
        }
    });
  },
  mounted () {
    this.init();

    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open')
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open')
        }
      });
    });
  },
  methods: {
    init() {
      this.oddsFormat = this.GetUserOddsFormat();
      this.SetSidebarColor();
    },
    deposit(){
      this.$root.$emit('openDepositDialog');
    },
    purchaseGold() {
      this.$root.$emit('openGoldDialog');
    },
    feedback() {
      this.$root.$emit('openFeedbackDialog');
    },
    purchaseToken(){
      this.$root.$emit('openPurchaseDialog');
    },
    sharedSeedmoney(){
      this.$root.$emit('openSeedmoneyDialog');
    },
    collapseAll() {
      var exp_element = document.getElementsByClassName("show");
      if (exp_element.length > 0) {
        var elm_id = exp_element[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input]
      return paths.some(path => {
        return this.$route.name.indexOf(path) === 0
      })
    },
    emailMask(email) {
      let result = email;
      function mask(str) {        
        return str.length > 5 ? str.substr(0, 3) + '...' + str.substr(-2, 2) : str;
      }
      return result.replace(/([\w.]+)@([\w.]+)(\.[\w.]+)/g, function (m, p1, p2, p3) {      
          return mask(p1) + '@' + mask(p2) + p3;
      });
    },
    openSocial(type) {
      let url = '';
      switch(type){
        case 'telegram':
          url = 'https://t.me/BOBwincommunity';
          break;
        case 'twitter':
          url = 'https://twitter.com/realgoodbettor';
          break;
        case 'discord':
          url = 'https://discord.com/invite/WbGxhVGca2';
          break;
        case 'kakao':
          url = 'https://open.kakao.com/o/gWbPcO4e';
          break;
        case 'instagram':
          url = 'https://www.instagram.com/goodbettor/';
          break;
        case 'facebook':
          url = 'https://www.facebook.com/groups/928020308612820/';
          break;
      }
      window.open(url,'_blank');
    },
    goDocument() {
        window.open('https://docs.bob.win');
    },
    onOddsFormatChange(){
      localStorage.setItem('oddsFormat', this.oddsFormat);
      this.$root.$emit('oddsFormatChanged', this.oddsFormat);
    },
    toggleMobileSidebar(event) {
      const fullscreen = document.querySelector('#sidebar-background');
      if (event.target === fullscreen) {
        fullscreen.classList.toggle('fullscreen');
        document.querySelector('#sidebar').classList.toggle('active');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.icon-account {
  font-size: 30px;
  padding: 5px 0;
}
.nav-link.pl-4 {
    padding-left: 2rem !important;
}
.dot-opacity-loader {
  width: 30px;
  height: 14px;
  margin: 0;
  span {
    background-color: #fff;
    width: 5px;
    height: 5px;
    margin: 1px 2px;
  }
}
.sidebar {
  .nav {
    position: relative;
    z-index: 1031;
  }
}
.btn-social-icon2 {
    background: rgba(38, 40, 41, 0.4);
    color: rgba(255, 255, 255, 0.8);
}
.fullscreen {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: rgba(38, 40, 41, 0.4);
  overflow: hidden; /* To prevent content from extending beyond the fullscreen div */
}

</style>