<template>
    <section class="generalDialog">
        <b-modal id="generalDialog-modal" class="userDetail-modal" :title="title" size="xl" button-size="lg" ok-only @click="handleOk">
            <div v-if="loading">
                <div class="pixel-loader"></div>
            </div>
            <div v-else>
                <div class="p-3">
                <h6 class="text-danger p-2" v-if="hasError">! {{ errorMessage }}</h6>
                </div>
            </div>
            <div v-if="title === 'Terms and Condition'">
              <terms />
            </div>
            <div v-if="title === 'Cookies Policy'">
              <cookies />
            </div>
            <div v-if="title === 'Privacy Policy'">
              <privacy />
            </div>
        </b-modal>
  </section>
</template>

<script>
import terms from '@/pages/auth/terms';
import privacy from '@/pages/auth/privacy';
import cookies from '@/pages/auth/cookies';

export default {
  name: 'general-dialog',
  components: {
    terms,
    privacy,
    cookies
  },
  props: ["title"],
  data(){
    return {
      hasError: '',
      errorMessage: '',
      loading: true,
    }
  },
  created() {
    this.loading = false;
  },
  methods: {
    handleOk() {

    }
  }
}
</script>