<template>
  <section class="nickname">

    <b-modal id="nickname-modal" class="nickname-modal" :title="$t('nickname')" size="md" button-size="lg" @ok="handleOk" :ok-disabled="okDisabled || processing">
      <div class="p-3">
        <div class="pt-0 pb-3">{{$t('nicknameMessage')}}</div>
        <b-form-input v-model="nickname" :class="['form-control','form-control-lg', hasError ? 'error' : '']" :placeholder="$t('nicknamePlaceholder')" required autocomplete="off" rows="3" max-rows="6" @input="initError" />
        <h6 class="text-danger p-2" v-if="hasError">! {{ errorMessage }}</h6>
      </div>
    </b-modal>
  </section>
</template>

<script>

export default {
  name: 'nickname',
  data(){
      return {
          nickname: '',
          hasError: '',
          errorMessage: '',
          processing: false,
          minLetter: 3,
          maxLetter: 20,
      }
  },
  created () {
      this.$root.$on('openNicknameDialog', this.openDialog);
      this.$bobwinHub.$on('nicknameUpdated', this.nicknameUpdated);
      this.processing = false;
  },
  beforeDestroy () {
    this.$root.$off('openNicknameDialog', this.openDialog);
    this.$bobwinHub.$off('nicknameUpdated', this.nicknameUpdated);
  },
  computed: {
    okDisabled() {
      const candidate = this.nickname.trim();
      return candidate == "" 
      || candidate.length == 0 
      || candidate.length > this.maxLetter
      || candidate.length < this.minLetter;
    }
  },
  watch: {
    'nickname': function(value) {
      if (value.trim().length > this.maxLetter) {
        this.errorMessage = this.$t('nicknamePlaceholder');
        this.hasError = true; 
      } else {
        this.initError();        
      }
    }
  },
  methods: {
      handleOk(bvModalEvt){
        bvModalEvt.preventDefault();
        if (!this.okDisabled) {
          this.processing = true;
          var param = {
            UserId: "00000000-0000-0000-0000-000000000000",
            Message: this.nickname.trim(),
          }
          this.CallHub({task: 'UpdateNickname', callback: 'nicknameUpdated', data: JSON.stringify(param)});
        }
      },
      openDialog() {
        this.$bvModal.show('nickname-modal');
      },
      initError() {
        this.hasError = false;
        this.errorMessage = '';
      },
      nicknameUpdated(response) {
        if (response.result == "error") {
          this.hasError = true;
          this.errorMessage = response.message == "ExistingNickname" ? this.$t('nicknameExist') : this.$t('contactSupport');
        } else {
          this.$store.state.user.nickname = this.nickname.trim();
          this.nickname = '';
          this.$bvModal.hide('nickname-modal');
          this.ShowSuccessMessage('');
        }
        this.processing = false;
      }
  }
}
</script>
<style scoped>
.radio-currency {
    width: 100%;
}
 .form-group {
      margin-bottom: 0.2rem;
    }
</style>