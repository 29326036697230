<template>
  <b-navbar id="template-header" class="p-0 fixed-top d-flex flex-row" toggleable="lg">
    <div class="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
      <router-link class="navbar-brand brand-logo-mini" to="/">
        <img src="@/assets/images/logo_450-340.png" alt="logo" />
      </router-link>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center ml-auto ml-lg-0 small-mobile-padding">
      <!--
      <button class="navbar-toggler navbar-toggler align-self-center d-lg-block" type="button" @click="toggleSidebar()">
        <span class="mdi mdi-sort-variant"></span>
      </button>
      -->

      <ul class="navbar-nav">
        <li class="nav-item d-md-block">
          <b-button to="/betleague" variant="outline-warning" class="betBtn ml-2 text-uppercase slide small-mobile-header">{{ $t('goToBet') }}</b-button>
          <b-button to="/leaderboard" variant="outline-success" class="betBtn ml-2 text-uppercase slide small-mobile-header">{{ $t('leaderBoard') }}</b-button>
          <!--<b-button to="/vote" variant="inverse-info" class="ml-2 text-uppercase"><i class="mdi mdi-playlist-check btn-icon-prepend" /> {{ $t('vote') }}</b-button>
          <b-button to="/createevent" variant="inverse-danger" v-if="IsLoggedIn" class="ml-2 text-uppercase"><i class="mdi mdi-plus btn-icon-prepend" /> {{ $t('createEvent') }}</b-button>
          -->
          </li>

        <!--li class="nav-item hidden">
          <form class="nav-link mt-2 mt-md-0 d-none d-lg-flex search">
            <input type="text" class="form-control" placeholder="Search">
          </form>
        </li-->
      </ul>

      <b-navbar-nav class="navbar-nav-right ml-auto">

        <b-button v-if="!IsLoggedIn" v-b-modal.signin-modal variant="success">{{$t('signin').toUpperCase()}}</b-button>
        <b-button v-if="!IsLoggedIn" v-b-modal.register-modal variant="success" class="ml-2">{{$t('register').toUpperCase()}}</b-button>
        <!--<b-button hidden v-if="!IsLoggedIn" variant="warning" type="button" class="ml-2" @click="ConnectWallet">CONNECT WALLET</b-button>-->


        <b-nav-item v-b-toggle.betslip class="d-lg-block" v-b-tooltip.top :title="$t('betslip')">
          <i class="far fa-list-alt" ></i>
        </b-nav-item>
       
      </b-navbar-nav>
      
      <button class="navbar-toggler navbar-toggler-right align-self-center" type="button" @click="toggleMobileSidebar()">
        <span class="mdi mdi-menu"></span>
      </button>
    </div>
    <betslip />
      <register />
      <signin />
  </b-navbar>
  
</template>

<script>
import betslip from './betslip.vue'
import register from '../../pages/auth/register.vue'
import signin from '../../pages/auth/signin.vue'

export default {
  name: 'app-header',
  components: {
    betslip,
    register,
    signin
  },
  methods: {
    toggleSidebar: () => {
      document.querySelector('body').classList.toggle('sidebar-icon-only');
    },
    toggleMobileSidebar: () => {
      document.querySelector('#sidebar-background').classList.toggle('fullscreen');
      document.querySelector('#sidebar').classList.toggle('active');
    },
    showCreateGame() {
      this.$refs.createGameModal.showModal();
    }  
  }
}
</script>
<style scoped>
.navbar-brand.brand-logo-mini img {
    width: 100%;
    height: 100%;
}
.btn i {
  font-size: 0.8rem;
}
.small-mobile-padding {
  @media (max-width:360px) {
      padding-right: 0px !important;
  }
}
.small-mobile-header {
  @media (max-width:360px) {
    font-size: 0.75rem !important;
  }
}
</style>