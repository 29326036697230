<template>
  <section class="gold-purchase">
    <b-modal id="gold-modal" class="gold-modal" :title="$t('depositToAccount')" size="md" @ok="handleOk" :okTitle="$t('ok')" :cancelTitle="$t('cancel')">
      <div>
        <b-form-group label="Select the amount" v-slot="{ ariaDescribedby }">
          <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="price_1MnZc0A7Ru9vqq18IYtvU9eG">Gold 50</b-form-radio>
          <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="price_1Mt053A7Ru9vqq18VjEYZVFN">Gold 100</b-form-radio>
          <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="price_1Mt05ZA7Ru9vqq18yfgvjGDt">Gold 200</b-form-radio>
          <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="price_1Mt05hA7Ru9vqq18fPuw6pXt">Gold 500</b-form-radio>
        </b-form-group>
    </div>
  </b-modal>
  </section>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      selected: 'price_1MnZc0A7Ru9vqq18IYtvU9eG'
    };
  },
  created() {
    this.$root.$on('openGoldDialog', () => {
      this.$bvModal.show('gold-modal');
    });
    this.$bobwinHub.$on('CreateStripeSessionReceived', this.CreateStripeSessionReceived);
  },
  beforeDestroy () {
    this.$bobwinHub.$off('CreateStripeSessionReceived', this.CreateStripeSessionReceived);
  },
  methods: {
    CreateStripeSessionReceived(response) {
      if (response.result == 'success') {
        const url = response.data;
        window.location.href = url;
      }
    },
    submit () {
      this.CallHub({task: 'CreateStripeSession', callback: 'CreateStripeSessionReceived', data:this.selected});
    },
    handleOk () {
      this.submit();
    }
  },
};
</script>