<template>
<div class="main-slider-container">
  <agile :progress="true" :autoplay="true" :autoplaySpeed="8000" :fade="true" timing="ease-in-out" :dots="false" :speed="600" class="agile">
    <!--
    <div class="slide slide1">
    <div class="d-lg-flex align-items-center">
        <div class="ml-5 pr-5 image-border d-none d-sm-block"><img src="@/assets/images/main-slider/main-image-1.png" alt="welcome"></div>
        <div class="pl-4">
        <h2 class="text-warning mb-2 text-700">{{$t('banner.ban1')}}</h2>
        <h5 class="pb-0 text-200">{{$t('banner.ban1sub')}}</h5>
        </div>
    </div>
    </div>
    
    <div class="slide slide2">
        <div class="d-lg-flex align-items-center">
            <div class="ml-5 pr-5 image-border d-none d-sm-block"><img src="@/assets/images/main-slider/sportsbet.png" alt="welcome"></div>
            <div class="pl-4">
            <h2 class="text-white mb-1 text-700">{{$t('banner.ban2')}}</h2>
            <h5 class="pb-0 mb-3 text-200">{{$t('banner.ban2sub')}}</h5>
            <b-button class="btn btn-success text-uppercase" @click="goDocument()"><i class="fas fa-question-circle"></i> {{$t('banner.howToPlay')}}</b-button>
            </div>
            <div class="pl-4">
            </div>
        </div>
    </div>

    <div class="slide slide3">
        <div class="d-lg-flex align-items-center">
            <div class="ml-5 pr-5 image-border d-none d-sm-block"><img src="@/assets/images/main-slider/banner3.png" alt="welcome"></div>
            <div class="pl-4">
            <h2 class="text-white mb-1 text-700">{{$t('banner.ban3')}}</h2>
            <h5 class="pb-0 mb-3 text-200">{{$t('banner.ban3sub')}}</h5>
            <b-button class="btn btn-warning text-uppercase" @click="goCIB()"><i class="fas fa-comments-dollar"></i> {{$t('banner.hereToBuy')}}</b-button>
            </div>
            <div class="pl-4">
            </div>
        </div>
    </div>

    <div class="slide slide4">
        <div class="d-lg-flex align-items-center">
            <div class="ml-5 pr-5 image-border d-none d-sm-block"><img src="@/assets/images/main-slider/wow649.png" alt="welcome"></div>
            <div class="pl-4">
            <h2 class="text-white mb-1 text-700">{{$t('banner.ban4')}}</h2>
            <h5 class="pb-0 mb-3 text-200">{{$t('banner.ban4sub')}}</h5>
            <b-button class="btn btn-success text-uppercase" @click="goWow649()"><i class="fas fa-play-circle"></i> {{$t('banner.goWow649')}}</b-button>
            <b-button class="btn btn-success text-uppercase ml-2" @click="goHowToGetGBob()"><i class="fas fa-question-circle"></i> {{$t('banner.howToGet')}}</b-button>
            </div>
            <div class="pl-4">
            </div>
        </div>
    </div>
    <div class="slide slide5">
        <div class="d-lg-flex align-items-center">
            <div class="ml-5 pr-5 image-border d-none d-sm-block"><img src="@/assets/images/main-slider/freeplaymode.png" alt="welcome"></div>
            <div class="pl-4">
            <h2 class="text-white mb-1 text-700">{{$t('banner.ban5')}}</h2>
            <h5 class="pb-0 mb-3 text-200">{{$t('banner.ban5sub')}}</h5>
            <b-button class="btn btn-success text-uppercase" @click="goFreePlay()"><i class="fas fa-play-circle"></i> {{$t('banner.goFreePlayMode')}}</b-button>
            </div>
            <div class="pl-4">
            </div>
        </div>
    </div>
    -->

    <div class="slide slide6">
        <div class="d-lg-flex align-items-center">
            <div class="ml-5 pr-5 image-border d-none d-sm-block"><img src="@/assets/images/main-slider/logo.png" alt="welcome"></div>
            <div class="pl-4">
            <h2 class="text-white mb-1 text-700">{{$t('banner.ban6')}}</h2>
            <h5 class="pb-0 mb-3 text-200">{{$t('banner.ban6sub')}}</h5>
            </div>
            <div class="pl-4">
            </div>
        </div>
    </div>
    <div class="slide slide7">
        <div class="d-lg-flex align-items-center">
            <div class="ml-5 pr-5 image-border d-none d-sm-block"><img src="@/assets/images/main-slider/sportsbet.png" alt="welcome"></div>
            <div class="pl-4">
            <h2 class="text-white mb-1 text-700">{{$t('banner.ban7')}}</h2>
            <h5 class="pb-0 mb-3 text-200">{{$t('banner.ban7sub')}}</h5>
            </div>
            <div class="pl-4">
            </div>
        </div>
    </div>

    <template v-slot:prevButton>
    <i class="fas fa-chevron-left"></i>
    </template>
    <template v-slot:nextButton>
    <i class="fas fa-chevron-right"></i>
    </template>

    </agile>
</div>
</template>

<script>
import { VueAgile } from 'vue-agile'
export default {
name: 'main-banner',
components: {
    agile: VueAgile
},
methods: {
    goDocument: () => {
        window.open('https://docs.bob.win');
    },
    goFreePlay: () => {
        window.open('https://goodbettor.com');
    },
    goWow649: () => {
        window.open('https://wow649.win');
    },
    goHowToGetGBob: () => {
        window.open('https://docs.bob.win/ecosystem/governance-or-gbob/how-can-i-earn-gbob');
    },
     goCIB: () => {
        window.open('https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x9fc2598e59860eafdbd853e54e7a34d4620b1c20');
    }
}
}
</script>
<style lang="scss" scoped>
.main-slider-container {
    background-color: #2e2f32;
    .slide {
        font-family: 'Poppins', sans-serif;
        padding: 1rem 2rem;
        background-size: cover !important;
        img {
            height: 120px;
        }
    }
/*    .slide1 {
        background: url('~@/assets/images/main-slider/bg1.jpg') no-repeat center;
    }
    .slide2 {
        background: url('~@/assets/images/main-slider/bg2.jpg') no-repeat top;
    }  
    .slide3 {
        background: url('~@/assets/images/main-slider/bg3.jpg') no-repeat center;
    }
    .slide4 {
        background: url('~@/assets/images/main-slider/aerial-view-ships-water.jpg') no-repeat center;
    }
    .slide5 {
        background: url('~@/assets/images/main-slider/bg4.png') no-repeat center;
    }*/
    .slide6 {
        background: url('~@/assets/images/main-slider/bg4.png') no-repeat center;
    }
    .slide7 {
        background: url('~@/assets/images/main-slider/bg4.png') no-repeat center;
    }
} 
</style>