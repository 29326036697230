<template>
  <section class="register">

    <b-modal id="register-modal" class="register-modal" :title="$t('register')" size="md" button-size="lg" hide-footer @hidden="init">
      <div class="auth-form-transparent text-left p-3">
        <h4 class="mb-4"> {{ $t('newhere') }} <span class="small font-weight-light"> {{ $t('joinustoday') }} </span></h4>
        <b-form @submit="register2">
          <b-form-group :label="$t('email')" label-for="email">
            <b-form-input v-model="email" id="email" type="email" :placeholder="$t('email')" :state="emailState" required />
          </b-form-group>

          <b-form-group :label="$t('password')" label-for="password">
            <div class="d-flex">
              <b-form-input v-model="password" id="password" :type="showPassword ? 'text' : 'password'" :placeholder="$t('password')" :state="pwState" required 
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                :title="$t('passwordCondition')" v-b-tooltip.top />
              <b-button type="button" @click="showPassword = !showPassword"><i class="fa fa-eye-slash" aria-hidden="true"></i></b-button>
            </div>
            <b-form-input v-model="passwordConfirm" id="passwordConfirm" :type="showPassword ? 'text' : 'password'" class="mt-2" :placeholder="$t('confirmPassword')" :state="pwState" required @input="onPwConfirmChange" />
          </b-form-group>

          <b-form-group :label="$t('birthYear')" label-for="birthYear">
            <datepicker id="birthYear" name="birthYear" input-class="form-control input-bg-black" :placeholder="$t('birthYear')" :open-date="openDate" minimum-view="year" :format="customFormatter" v-model="birthYear" :disabled-dates="state.disabledDates" />
          </b-form-group>

          <b-form-group>                  
              <b-form-checkbox name="check-button" size="lg" switch  class="d-flex  align-items-center" v-model="agree" :title="$t('enableBettingAfterEventStart')" required /> 
              <div class="pl-5">
                <h6><span>{{$t('registration.sub1')}} </span><span class="linked" v-on:click="showDialog('Terms and Condition')">Terms and Conditions</span><span>, </span><span>Rules</span><span>, </span><span class="linked" v-on:click="showDialog('Privacy Policy')">Privacy Policy</span><span>, </span><span class="linked" v-on:click="showDialog('Cookies Policy')">Cookies Policy</span><span> {{$t('registration.sub2')}}</span></h6>
              </div>
            </b-form-group>

          <div class="text-danger" v-if="emailState === false || pwState === false">! {{ errorMessage }}</div>

          <div class="mt-3">
              <b-button type="submit" variant="primary" :disabled="processing" class="btn-block btn-lg font-weight-medium">{{ $t('register').toUpperCase() }}</b-button>
          </div>

          <div class="text-center mt-4 font-weight-light"> {{ $t('signinQuestion') }}  <a href="#" @click="goSignin" class="text-warning">{{ $t('signin') }}</a>
          </div>
        </b-form>
      </div>
      <GeneralDialog :title="dialogTitle" />
    </b-modal>
  </section>

</template>

<script>
import axios from 'axios'
import Datepicker from 'vuejs-datepicker'
import GeneralDialog from '@/pages/dialogs/general-dialog'

export default {
  name: 'register',
  data(){
    return {
        email: '',
        password: '',
        passwordConfirm: '',
        showPassword: false,
        errorMessage: '',
        emailState: null,
        pwState: null,
        birthYear: '',
        processing: false,
        agree: false,
        dialogTitle: '',
        state : {
          disabledDates: {
            from: new Date(),
          }
        },
        openDate: new Date("1982")
    }
  },
  components: {
    Datepicker,
    GeneralDialog
  },
  created() {
  },
  methods: {
    init(){
      this.email = '';
      this.password = '';
      this.passwordConfirm = '';
      this.emailState = null;
      this.pwState = null;
      this.birthYear = '';
      this.processing = false;
      this.agree = false;
    },
    register2(event){
      event.preventDefault();
      if(this.pwConfirmValidate() && this.birthYearValidate() && this.agree && !this.processing){
        this.processing = true;
        var param = {
          Email: this.email,
          Password: this.password,
          BirthYear: this.birthYear
        }
        axios.post('/api/Users/register', param)
        .then(response => {
          this.processing = false;
          if(response.data.result.result === 'success') {
            this.goSignin();
          }
          else {
            this.emailState = false;
            this.errorMessage = response.data.result.message;
          }
        })
        .catch(error => {
          window.console.error(error)
        });
      }
    },
    goSignin(){
        this.$bvModal.hide('register-modal');
        this.$bvModal.show('signin-modal');
    },
    onPwConfirmChange(){
      this.pwState = null;
    },
    pwConfirmValidate(){
      if(this.password !== this.passwordConfirm) {
        this.pwState = false;
        this.errorMessage = this.$t('passwordNotMatched');
        return false;
      }
      return true;
    },
    birthYearValidate() {
      if (this.birthYear === '')
      {
        this.emailState = false;
        this.errorMessage = this.$t('birthYearEmpty');
        return false;
      }
      return true;
    },
    customFormatter(date) {
      return this.$moment(date).format('YYYY');
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today; 
    },
    showDialog(type) {
      this.dialogTitle = type;
      this.$bvModal.show('generalDialog-modal');
    }
  }
}
</script>

<style lang="scss">
.input-bg-black {
  background-color: #2A3038 !important;
}
.custom-switch .custom-control-label::before {
    top: 1.46rem !important;
    cursor: pointer;
}
.custom-switch .custom-control-label::after {
    top: 1.6rem !important;
    cursor: pointer;
}
.linked {
  font-weight: bold;
  cursor: pointer;
  color: orange;
}
</style>