<template>
  <section class="deposit">

    <b-modal id="deposit-modal" class="deposit-modal" :title="$t('deposit')" size="md" button-size="lg" @ok="handleOk">
      <div class="p-3">
        <b-form-group label="" v-slot="{ ariaDescribedby }" >
          <b-form-radio-group
            id="btn-radios-2"
            v-model="selected"
            :options="options"
            :aria-describedby="ariaDescribedby"
            button-variant="outline-primary"
            size="lg"
            name="radio-btn-outline"
            class="radio-currency"
            @change="onCurrencyChange"
            buttons />
        </b-form-group>

        <div class="pt-0 pb-3">{{$t('depositAvailable') + ': ' + availableAmount }}</div>
        <h6 class="text-danger p-2" v-if="hasError">! {{ errorMessage }}</h6>
      </div>
    </b-modal>

  </section>
</template>

<script>
export default {
data(){
    return {
        availableAmount: 0,
        amount: 0,
        defaultAmountUSDT: 100,
        defaultAmountCIB: 10,
        selected: '2',
        options: [
          { text: 'Play Money', value: '2' }
        ],
        hasError: '',
        errorMessage: ''
    }
},
created () {
   this.$root.$on('deposit', () => {
        this.selected = '2';
        this.amount = this.defaultAmountUSDT;
        this.$bvModal.show('deposit-modal');

        var param = {
          UserId: this.UserId,
          TokenType: this.selected
        }
        this.CallHub({task: 'GetDepositLimit', callback: 'depositLimitReceived', data: JSON.stringify(param)});
    });

    this.$bobwinHub.$on('depositLimitReceived', response => {
      if(response.result === 'success') {
        let result = JSON.parse(response.data);
        this.availableAmount = result;
        let defaultAmount = this.selected == '2' ? this.defaultAmountUSDT : this.defaultAmountCIB;
        this.amount = Number(result) > defaultAmount ? defaultAmount : result;
      }
    });

    this.$bobwinHub.$on('depositSaved', response => {
      if(response.result === 'success') {
        let user = JSON.parse(response.userData);
        this.$store.dispatch('setUser', user);
        this.$bvModal.hide('deposit-modal');
      }
      else {
        this.hasError = true;
        this.errorMessage = response.message;
      }
    });

    this.$root.$on('openDepositDialog', () => {
        this.selected = '2';
        this.amount = 0; //this.defaultAmountUSDT;
        this.$bvModal.show('deposit-modal');

        var param = {
          UserId: this.UserId,
          TokenType: this.selected
        }
        this.CallHub({task: 'GetDepositLimit', callback: 'depositLimitReceived', data: JSON.stringify(param)});
    });
},
methods: {
    onCurrencyChange(){
      this.$nextTick(() => {
      var param = {
        UserId: this.UserId,
        TokenType: this.selected
      }
      this.CallHub({task: 'GetDepositLimit', callback: 'depositLimitReceived', data: JSON.stringify(param)});
      });
    },
    onAmountChange() {
      this.hasError = false;
      this.errorMessage = '';
    },
    handleOk(bvModalEvt){
      bvModalEvt.preventDefault();

      if(this.amount > 0){
        var param = {
          UserId: this.UserId,
          Amount: this.amount,
          TokenType: this.selected
        }
        this.CallHub({task: 'SaveDeposit', callback: 'depositSaved', data: JSON.stringify(param)});
      }
    }

  }
}
</script>
<style scoped>
.radio-currency {
    width: 100%;
}
 .form-group {
      margin-bottom: 0.2rem;
    }
</style>