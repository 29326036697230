import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import QuickEdit from 'vue-quick-edit'
import axios from 'axios'
import VueAxios from 'vue-axios'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import mixin from './mixins/mixin'
import BobwinHub from './mixins/bobwin-hub'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import i18n from "./i18n"
import VueTour from 'vue-tour'

import MediumEditor from 'vuejs-medium-editor'
import 'medium-editor/dist/css/medium-editor.css'
import 'vuejs-medium-editor/src/themes/default.css'
import 'highlight.js/styles/ocean.css'

require('vue-tour/dist/vue-tour.css')

Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_APIURL

Vue.mixin(mixin)
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.use(VueAxios, axios)
Vue.use(PerfectScrollbar)
Vue.use(BobwinHub)
Vue.use(Vuex)
Vue.use(VueTour)
Vue.component('quick-edit', QuickEdit)
Vue.component('v-select', vSelect)
Vue.component('medium-editor', MediumEditor)
Vue.prototype.$moment = moment

const store = new Vuex.Store({
  state: {
    user: null,
    categoryData: [],
    web3Info: [],
    bobUTCTime: ''
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setCategoryData(state, data) {
      state.categoryData = data;
    },
    setWeb3Info(state, data) {
      state.web3Info = data;
    },
    setBobClock(state, data){
      state.bobUTCTime = data;
    }
  },
  actions: {
    setUser(context, user) {
      context.commit("setUser", user);
    },
    setCategoryData(context, data) {
      context.commit("setCategoryData", data);
    },
    setWeb3Info(context, data) {
      context.commit("setWeb3Info", data);
    },
    setBobClock(context, data) {
      context.commit("setBobClock", data);
    }
  },
  plugins: [createPersistedState()]
});

new Vue({
  router,
  store: store,
  i18n,
  render: h => h(App)
}).$mount('#app')
