<template>
  <section class="app-footer">
    <footer class="footer">
      <div class="container-fluid clearfix">
        <div class="text-muted float-left d-block text-sm-left d-sm-inline-block">&copy; {{ new Date().getFullYear() }} <a href="https://goodbettor.com/" target="_blank" class="text-warning">Good Bettor</a>. All rights reserved.</div>
        <div class="text-muted float-left d-block text-sm-left d-sm-inline-block"><span class="linked" v-on:click="showDialog('Terms and Condition')"> Terms and Conditions</span><span>, </span><span class="linked" v-on:click="showDialog('Privacy Policy')">Privacy Policy</span><span>, </span><span class="linked" v-on:click="showDialog('Cookies Policy')">Cookies Policy</span></div>
        <div class="text-muted float-right d-block text-sm-right d-sm-inline-block">{{ timezone }}</div>
      </div> 
      <GeneralDialog :title="dialogTitle" />     
    </footer>
  </section>
</template>

<script>
import GeneralDialog from '@/pages/dialogs/general-dialog'

export default {
  name: 'app-footer',
  data(){
    return {
        dialogTitle: ''
    }
  },
  components: {
    GeneralDialog
  },
  computed: {
    timezone(){
      let timeZone = this.$moment.tz.guess();
      let zoneAbbr = this.$moment.tz(timeZone).zoneAbbr();
      return zoneAbbr + ' (UTC ' + this.$moment().format('Z')+')';
    }
  }, 
  methods: {
    showDialog(type) {
      this.dialogTitle = type;
      this.$bvModal.show('generalDialog-modal');
    }
  }
}
</script>


<style scoped lang="scss">
.linked {
  font-weight: bold;
  cursor: pointer;
  color: orange;
}
</style>